import React, { useState, useEffect } from "react";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";

import Button from "../Button";
import Accordion from "../Accordion";
import Snackbar from "../../components/Snackbar";

import "./style.css";
import SuggestionButton from "../SuggestionButton";
import CorrectAnswerRecord from "./correctAnswerRecord";

import { useTranslation } from "react-i18next";
import cx from "classnames";

const QuizReport = ({
  submissionData,
  type,
  onClick,
  redirectToChat,
  hideScores,
  displayedQuestionsId,
  enableLearning,
  hideMainButton,
  userScore,
}) => {
  const { t } = useTranslation('translation');
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [displayScores, setDisplayScores] = useState(!hideScores);
  const [totalScore, setTotalScore] = useState(0);
  const [questionsToReview, setQuestionsToReview] = useState([]);
  const [resetCheckboxes, setResetCheckboxes] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const makeApiCall = useFetch();

  useEffect(() => {
    transformAllQuestions();
    if ("show_scores" in submissionData.result_settings) {
      setDisplayScores(submissionData.result_settings.show_scores);
    }
  }, []);

  const transformAnswers = (options) => {
    if(!options){
      return []
    }
    return options.filter((option, key) => option.is_correct === true);
  };

  const transformAllQuestions = () => {
    let temp = {};
    submissionData.quiz &&
      submissionData.quiz.questions.forEach((question) => {
        if (question.parent_id && temp[question.parent_id]) {
          temp[question.parent_id] = {
            ...temp[question.parent_id],
            subquestions:
              "subquestions" in temp[question.parent_id]
                ? temp[question.parent_id].subquestions.concat([question])
                : [].concat([question]),
          };
        } else {
          temp[question.id] = question;
        }
      });

    let temp1 = {};
    submissionData.answers &&
      submissionData.answers.forEach((answer) => {
        temp1[answer.question_id] = answer;
      });
    computeReport(temp, temp1);
  };

  const transformSubmittedOptions = (submittedOptions, questionOptions) => {
    let transformedOptions = [];

    submittedOptions &&
      submittedOptions.forEach((submittedOptionId) => {
        questionOptions.forEach((questionOption) => {
          if (submittedOptionId === questionOption.id) {
            transformedOptions.push(questionOption);
          }
        });
      });

    return transformedOptions;
  };

  const prepareCorrectAnswerList = (element, userAnswers) => {
    let dataToPush = {};
    dataToPush = {
      questionId: element.id,
      questionType: element.type,
      question: element.text,
      questionScoredBy: element?.scored_by,
      ideal_answer: element?.ideal_answer,
      document_references:  element?.document_references,
      answers:
        element.type === "group"
          ? []
          : "ideal_answer_items" in element &&
            (element.type === "table" || element.type === "newbullet")
          ? [{ bullets: element.ideal_answer_items }]
          : "ideal_answer_html" in element &&
            element.type !== "description" &&
            element.type !== "radio" &&
            element.type !== "checkbox"
          ? [{ html: element.ideal_answer_html }]
          : "ideal_answer" in element &&
            element.type !== "radio" &&
            element.type !== "checkbox"
          ? [{ text: element.ideal_answer }]
          : "matches" in element
          ? [{ matches: element.matches }]
          : transformAnswers(element.options),
      submittedAnswers: !userAnswers[element.id]
        ? []
        // : "html" in element && element.type === "bullet"
        // ? [{ html: userAnswers[element.id].html }]
        : "text" in userAnswers[element.id]
        ? [{ text: userAnswers[element.id].text }]
        : "matches" in element
        ? [{ matches: userAnswers[element.id].matches }]
        : element.type !== "group"
        ? transformSubmittedOptions(
            userAnswers[element.id].options,
            element.options
          )
        : "",
      questionScore:
        submissionData.quiz.score_decimal_number === 0
          ? Math.floor(element.score)
          : element.score.toFixed(
              submissionData.quiz.score_decimal_number === null
                ? 2
                : submissionData.quiz.score_decimal_number
            ),
      userScore: !userAnswers[element.id]
        ? ""
        : "score" in userAnswers[element.id]
        ? submissionData.quiz && submissionData.quiz.score_decimal_number === 0
          ? Math.floor(userAnswers[element.id].score)
          : parseFloat(userAnswers[element.id].score).toFixed(
              submissionData.quiz.score_decimal_number === null
                ? 2
                : submissionData.quiz.score_decimal_number
            )
        : "-",
      answerKeySettings:
        "answer_key_settings" in element ? element.answer_key_settings : null,
      answerFeedback: userAnswers?.[element?.id]?.feedback,
      options: element.options ? element.options : []
    };

    if (element.subquestions) {
      let temp = [];
      element.subquestions.forEach((subque) => {
        temp.push(prepareCorrectAnswerList(subque, userAnswers));
      });
      dataToPush.subquestionAnswers = temp;
    }

    return dataToPush;
  };

  const computeReport = (quizQuestions, userAnswers) => {
    let totalScore = 0;
    let correctAnswerList = [];
    Object.keys(quizQuestions).forEach((questionId) => {
      if (
        (quizQuestions[questionId].options ||
          "ideal_answer_items" in quizQuestions[questionId] ||
          "ideal_answer_html" in quizQuestions[questionId] ||
          "ideal_answer" in quizQuestions[questionId] ||
          "matches" in quizQuestions[questionId] ||
          quizQuestions[questionId].type === "group") &&
        displayedQuestionsId.indexOf(quizQuestions[questionId].id) !== -1
      ) {
        totalScore += quizQuestions[questionId].score;
        correctAnswerList.push(
          prepareCorrectAnswerList(quizQuestions[questionId], userAnswers)
        );
      }
    });
    setCorrectAnswers(correctAnswerList);
    if (submissionData.quiz_score) {
      setTotalScore(submissionData.quiz_score);
    } else {
      setTotalScore(totalScore);
    }
  };

  const onReviewRequest = (questionId, action, reviewComment) => {
    let temp = [...questionsToReview];

    if (action === "add") {
      temp.push({ question_id: questionId, comment: reviewComment });
    } else if (action === "remove") {
      temp.forEach((item, index) => {
        if (item.question_id === questionId) {
          temp.splice(index, 1);
        }
      });
    } else if (action === "change") {
      temp.forEach((item, index) => {
        if (item.question_id === questionId) {
          item.comment = reviewComment;
        }
      });
    }
    setQuestionsToReview(temp);
  };

  const sendForReview = () => {
    setResetCheckboxes((state) => !state);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        quiz_submission_id: submissionData._id,
        to_review_answers: questionsToReview,
      }),
    };
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/learning/submission-reviews",
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          setSnackbar({
            isOpen: true,
            message: t("Sent answers for review"),
            type: "success",
          });
          setQuestionsToReview([]);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const cancelReview = () => {
    setResetCheckboxes((state) => !state);
    setQuestionsToReview([]);
  };

  return (
    <div className="quiz-submission-page">
      <h4 role="alert">{t("Thank you for completing the quiz!")}</h4>
      {submissionData.quiz && submissionData.quiz.score !== 0 && displayScores && (
        <>
          <h4>{t("You Scored")}</h4>
          <div className="score-container">
            {
              submissionData.result_settings &&
              submissionData.result_settings.show_scores_in_percentage
                ? (<div className="score-badge">
                    {userScore !== undefined
                      ? Math.floor((userScore/totalScore) * 100)
                      : submissionData.score !== undefined
                      ? Math.floor((submissionData.score/totalScore) * 100)
                      : "NA"
                    }
                    <span className="total-score-number">%</span>
                  </div>)
                : (<div className="score-badge">
                    {userScore !== undefined
                      ? submissionData.quiz.score_decimal_number === 0
                        ? Math.floor(userScore)
                        : userScore.toFixed(
                            submissionData.quiz.score_decimal_number === null
                              ? 2
                              : submissionData.quiz.score_decimal_number
                          )
                      : submissionData.score !== undefined
                      ? submissionData.quiz.score_decimal_number === 0
                        ? Math.floor(submissionData.score)
                        : submissionData.score.toFixed(
                            submissionData.quiz.score_decimal_number === null
                              ? 2
                              : submissionData.quiz.score_decimal_number
                          )
                      : "NA"}
                    <span className="total-score-number">/{totalScore}</span>
                  </div>)
            }
          </div>
        </>
      )}

      {
      // submissionData && submissionData.result_settings &&
      //   submissionData.result_settings.show_answers && 
        correctAnswers.length > 0 && (
          <div className="correct-answers-container">
            <Accordion title={t("Check out the correct answers to the quiz questions")}>
              {correctAnswers.map((item, index) => (
                <CorrectAnswerRecord
                  item={item}
                  questionNumber={index + 1}
                  questionId={item.questionId}
                  questionType={item.questionType}
                  question={item.question}
                  idealAnswer={item?.ideal_answer}
                  answers={item.answers}
                  document_references={item.document_references}
                  submittedAnswers={item.submittedAnswers}
                  questionScore={item.questionScore}
                  userScore={item.userScore}
                  answerKeySettings={item.answerKeySettings}
                  subquestionsData={item.subquestionAnswers}
                  onReviewRequest={onReviewRequest}
                  resetCheckboxes={resetCheckboxes}
                  enableLearning={enableLearning}
                  showAllScores={
                    submissionData.quiz.score !== 0 && displayScores
                  }
                  conditions={
                    submissionData.result_settings &&
                    submissionData.result_settings.shows
                      ? submissionData.result_settings.shows
                      : []
                  }
                  onConditionButtonClick={onClick}
                  displayedQuestionsId={displayedQuestionsId}
                  submissionData={submissionData}
                  answerFeedback={item?.answerFeedback}
                  questionScoredBy={item?.questionScoredBy}
                />
              ))}
            </Accordion>
          </div>
        )}

      {/* submissionData.result_settings.show_answers && */}

      {submissionData.result_settings &&
        submissionData.result_settings?.shows?.length > 0 &&
        submissionData.result_settings.shows
          .filter((show) => !show.question_id)
          .map((show) => (
            <>
              {show.message && (
                <div className="condition-message">
                  <div className="half-clearfix" />
                  <div className={cx({"isStrong": show.message.includes("<strong>")})} dangerouslySetInnerHTML={{ __html: show.message }} />
                </div>
              )}
              {show.buttons && (
                <div className="condition-buttons">
                  {show.buttons.map((button, index) => (
                    <SuggestionButton
                      key={index.toString()}
                      buttonLabel={button.label}
                      buttonAction={() =>
                        onClick(
                          button,
                          "quiz",
                          displayedQuestionsId,
                          submissionData
                        )
                      }
                    />
                  ))}
                </div>
              )}
            </>
          ))}
      {/* {submissionData.result_settings &&
        submissionData.result_settings?.shows?.length > 0 &&
        submissionData.result_settings.show_answers &&
          submissionData.result_settings.shows.map((show) => (
          <>
            {show.message && (
              <div className="condition-message">
                <div className="half-clearfix" />
                <div dangerouslySetInnerHTML={{ __html: show.message }} />
              </div>
            )}
            {show.buttons && (
              <div className="condition-buttons">
                {show.buttons.map((button, index) => (
                  <SuggestionButton
                    key={index.toString()}
                    buttonLabel={button.label}
                    buttonAction={() =>
                      onClick(
                        button,
                        "quiz",
                        displayedQuestionsId,
                        submissionData
                      )
                    }
                  />
                ))}
              </div>
            )}
          </>
        ))} */}
      {submissionData.result_settings.show_button && !hideMainButton && (
        <div className="main-action-container">
          <Button
            buttonClass="primary-button custom-button"
            onClick={() =>
              onClick(submissionData, "backToChat", displayedQuestionsId)
            }
          >
            {submissionData.result_settings.button_label
              ? submissionData.result_settings.button_label
              : t("Continue to chat")}
          </Button>
        </div>
      )}

      {questionsToReview.length > 0 && (
        <div className="send-review-action-panel" style={{
          top: 70
        }}>
          {t("You are sending")} {questionsToReview.length} {t("answers for review.")}
          <button className="send-review-btn" onClick={sendForReview}>
            {t("Send for review")}
          </button>
          <button className="cancel-review-btn" onClick={cancelReview}>
            {t("Cancel")}
          </button>
        </div>
      )}
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default QuizReport;
