import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import cx from "classnames";
import useFetch from "../../helpers/remote";
import { convertToBlob, base64MimeType } from "../../utils/formatFunctions";
import LinkOption from "./linkOption";
import EmojiOption from "./emojiOption";
import BubbleBreakOption from "./bubbleBreakOption";
import InsertVariableOption from "./insertVariableOption";
import SymbolOption from "./symbolOption";
import PeriodicTableOption from "./periodicTableOption";
import { convertFromRaw, EditorState, ContentState, Modifier } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import { emojiList } from "./data";
import bold from "../../assets/images/editor/bold.svg";
import underline from "../../assets/images/editor/underline.svg";
import italic from "../../assets/images/editor/italic.svg";
import strike from "../../assets/images/editor/strike.svg";
import subscript from "../../assets/images/editor/subscript.svg";
import superscript from "../../assets/images/editor/superscript.svg";
import bulletlist from "../../assets/images/editor/bullet-list.svg";
import numberlist from "../../assets/images/editor/number-list.svg";
import video from "../../assets/images/editor/video.svg";
import emoji from "../../assets/images/editor/emoji.svg";
import image from "../../assets/images/editor/image.svg";
import undo from "../../assets/images/editor/undo.svg";
import redo from "../../assets/images/editor/redo.svg";
import link from "../../assets/images/editor/link.svg";

import { useTranslation } from "react-i18next";
import { setState } from "react-jsonschema-form/lib/utils";

const CustomUndoButton = ({ editorState, onChange }) => {
  const canUndo = editorState.getUndoStack().size > 0;

  const handleUndo = () => {
    onChange(EditorState.undo(editorState));
  };

  return (
    <button
      className={`rdw-option-wrapper markdown-toolbar-option ${canUndo ? "" : "rdw-option-disabled"}`}
      onClick={handleUndo}
      aria-label="Undo"
      title="Undo"
      disabled={!canUndo}
    >
      <img src={undo} alt="Undo" />
    </button>
  );
};

const CustomRedoButton = ({ editorState, onChange }) => {
  const canRedo = editorState.getRedoStack().size > 0;
  const handleRedo = () => {
    onChange(EditorState.redo(editorState));
  };

  return (
    <button 
      className={`rdw-option-wrapper markdown-toolbar-option ${canRedo ? "" : "rdw-option-disabled"}`}
      onClick={handleRedo}
      aria-label="Redo"
      title="Redo"
      disabled={!canRedo}
    >
      <img src={redo} alt="Redo" />
    </button>
  );
};

const MarkdownEditor = ({
  label,
  description,
  value,
  onChange,
  name,
  placeholder,
  disabled,
  id,
  autoFocus,
  ref,
  required,
  showError,
  errorMessage,
  isQuizAnswer,
  maxEssayChars,
}) => {
  const { t } = useTranslation("translation");
  const makeApiCall = useFetch();
  const [text, setText] = useState(value);

  const handleEmbedCallback = (link) => {
    console.log("callback", link);
    let videoPattern = /(http(s?):)([/|.|\w|\s|-])*\.(?:mp4)/gim;
    let youtubePattern =
      /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/(watch\?v=|embed)(\S+)/gim;
    let vimeoPattern =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/gim;
    return link
      .replace(
        videoPattern,
        '<video controls> <source src="$&" type="video/mp4"> </video>'
      )
      .replace(youtubePattern, "https://www.youtube.com/embed/$8")
      .replace(vimeoPattern, "https://player.vimeo.com/video/$4");
  };

  const toBase64 = (file, callBack) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callBack(file, reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const uploadToCloud = (file_data) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("media", file_data);

      makeApiCall
        .request("upload", window.ADMIN_API + "/api/v1/media/images/upload", {
          method: "POST",
          body: formData,
        })
        .then((json) => {
          if (json.status.code === 200) {
            resolve({ data: { link: json.data.media.url } });
          } else {
            reject(json);
          }
        });
    });
  };

  const handleLinkCallback = (linkObject) => {
    console.log(linkObject);
    return linkObject;
  };

  const toolbarQuiz = {
    options: [
      // "inline",
      // "list",
      // "link",
      // "embedded",
      // "emoji",
      // "image",
      // "history",
    ],
    inline: {
      inDropdown: true,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: [
        "bold",
        "italic",
        // "underline",
        // "strikethrough",
        // "monospace",
        // "superscript",
        // "subscript",
      ],
      bold: { icon: bold, className: "markdown-toolbar-icon" },
      italic: { icon: italic, className: "markdown-toolbar-icon" },
      underline: { icon: underline, className: "markdown-toolbar-icon" },
      strikethrough: { icon: strike, className: "markdown-toolbar-icon" },
      // monospace: { icon: monospace, className: "markdown-toolbar-icon" },
      superscript: { icon: superscript, className: "markdown-toolbar-icon" },
      subscript: { icon: subscript, className: "markdown-toolbar-icon" },
    },
    list: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["unordered", "ordered"],
      unordered: { icon: bulletlist, className: "markdown-toolbar-icon" },
      ordered: { icon: numberlist, className: "markdown-toolbar-icon" },
      // indent: { icon: indent, className: undefined },
      // outdent: { icon: outdent, className: undefined },
    },
    link: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: LinkOption,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: false,
      defaultTargetOption: "_blank",
      options: ["link"],
      link: { icon: link, className: undefined },
      // unlink: { icon: unlink, className: undefined },
      linkCallback: undefined,
    },
    emoji: {
      icon: emoji,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      emojis: emojiList,
    },
    embedded: {
      icon: video,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      embedCallback: handleEmbedCallback,
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    image: {
      icon: image,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: uploadToCloud,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "400",
      },
    },
    history: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["undo", "redo"],
      undo: { icon: undo, className: undefined, role: "button" },
      redo: { icon: redo, className: undefined, role: "button" },
    },
  };

  const toolbar = {
    options: [
      "inline",
      "list",
      "link",
      // "embedded",
      // "history",
      "image",
      "emoji",
    ],
    inline: {
      inDropdown: true,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: [
        "bold",
        "italic",
        // "underline",
        // "strikethrough",
        // "monospace",
        // "superscript",
        // "subscript",
      ],
      bold: { icon: bold, className: "markdown-toolbar-icon" },
      italic: { icon: italic, className: "markdown-toolbar-icon" },
      underline: { icon: underline, className: "markdown-toolbar-icon" },
      strikethrough: { icon: strike, className: "markdown-toolbar-icon" },
      // monospace: { icon: monospace, className: "markdown-toolbar-icon" },
      superscript: { icon: superscript, className: "markdown-toolbar-icon" },
      subscript: { icon: subscript, className: "markdown-toolbar-icon" },
    },
    list: {
      inDropdown: true,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["unordered", "ordered"],
      unordered: { icon: bulletlist, className: "markdown-toolbar-icon" },
      ordered: { icon: numberlist, className: "markdown-toolbar-icon" },
      // indent: { icon: indent, className: undefined },
      // outdent: { icon: outdent, className: undefined },
    },
    link: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: LinkOption,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: false,
      defaultTargetOption: "_blank",
      options: ["link"],
      link: { icon: link, className: undefined },
      // unlink: { icon: unlink, className: undefined },
      linkCallback: undefined,
    },
    emoji: {
      icon: emoji,
      className: "markdown-toolbar-option",
      component: EmojiOption,
      popupClassName: undefined,
      emojis: emojiList,
    },
    embedded: {
      icon: video,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      embedCallback: handleEmbedCallback,
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    image: {
      icon: image,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: uploadToCloud,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "400",
      },
    },
    history: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["undo", "redo"],
      undo: { icon: undo, className: undefined, role: "button" },
      redo: { icon: redo, className: undefined, role: "button" },
    },
  };

  const getLength = (state) => {
    const text = state?.getCurrentContent()?.getPlainText("");
    // console.log({text})
    return text?.length || 0;
  };

  const onEditorChange = (state) => {
    setText(state);
    if (getLength(state) <= maxEssayChars) {
      onChange(state, id);
    } else if(getLength(state) > (maxEssayChars + 1)){
      const truntString = state?.getCurrentContent().getPlainText("").slice(0, maxEssayChars);
      const newState = EditorState.createWithContent(ContentState.createFromText(truntString))
      onChange(newState, id)
    } else {
      onChange(state, id);
    }
  };

  return (
    <div className="markdown-container">
      {label && (
        <label className="markdown-label">
          {label}
          <div className="markdown-description">{description}</div>
        </label>
      )}
      <Editor
        stripPastedStyles={true}
        editorState={text}
        toolbar={isQuizAnswer ? toolbarQuiz : toolbar}
        toolbarClassName="toolbarClassName"
        wrapperClassName="markdown-editor-wrapper"
        handlePastedText={() => false}
        onEditorStateChange={onEditorChange}
        // customBlockRenderFunc={getBlockRenderFunc}
        toolbarCustomButtons={
          isQuizAnswer
            ? [<CustomUndoButton />, <CustomRedoButton />, <SymbolOption />, <PeriodicTableOption />]
            : [
                <CustomUndoButton />,
                <CustomRedoButton />, 
                <SymbolOption />,
                <PeriodicTableOption />,
                <BubbleBreakOption />,
                <InsertVariableOption />,
              ]
        }
        placeholder={
          placeholder
            ? placeholder
            : t(
                "Type here. Quick tip: Click on 🔗 in the above toolbar to insert video, image and website links."
              )
        }
      />
      {maxEssayChars && (
        <div
          className={cx("text-right text-gray-400 text-xs text-error", {
            "text-red-700": getLength(value) >= maxEssayChars,
          })}
          role="alert"
        >
          {t("Characters: ")}
          {value.getCurrentContent().getPlainText("")?.length || 0} /{" "}
          {maxEssayChars}
        </div>
      )}
      {showError && <div className="markdown-error">{errorMessage}</div>}
    </div>
  );
};

MarkdownEditor.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

MarkdownEditor.defaultProps = {
  inputClass: "",
  value: "",
};

export default MarkdownEditor;
