import * as CONFIG from "./routeMapping";

import Chat from "../containers/Chat";
import NewChat from "../containers/NewChat";
import NewSSEChat from "../containers/NewChat/new-sse";
import QuizReview from "../containers/QuizReview";
import NoAgentFound from "../components/NoAgentFound";
import TrophyRoom from "../containers/TrophyRoom";
import BadgePage from "../containers/TrophyRoom/badgePage";
import AllNotifications from "../containers/AllNotifications";
import LoginWidget from "../containers/LoginWidget";
import WidgetLoginPage from "../containers/WidgetLoginPage";
import UserReport from "../containers/UserReport";
import SLSConfirm from "../containers/SLSConfirm";
import Discussion from "../containers/TeamDiscussion";
import DiscussionDetail from "../containers/TeamDiscussion/DiscussionDetail";

export const webChatRoutes = [
  { path: CONFIG.HOME, component:  NewChat},
  { path: CONFIG.OLD_HOME, component: Chat },
  { path: CONFIG.NEW_HOME, component:  NewChat},
  { path: CONFIG.QUIZ_REVIEW, component: QuizReview },
  { path: CONFIG.NO_AGENT, component: NoAgentFound },
  { path: CONFIG.TROPHY_ROOM, component: TrophyRoom },
  { path: CONFIG.BADGES, component: BadgePage },
  { path: CONFIG.NOTIFICATIONS, component: AllNotifications },
  { path: CONFIG.USER_REPORT, component: UserReport },
  { path: CONFIG.SLS_CONFIRM, component: SLSConfirm },
  { path: CONFIG.NEW_SSE, component: NewSSEChat },
];

export const webChatAsWidgetRoutes = [
  { path: CONFIG.HOME, component:  LoginWidget},
  { path: CONFIG.OLD_HOME, component: Chat },
  { path: CONFIG.NEW_HOME, component:  LoginWidget},
  { path: CONFIG.QUIZ_REVIEW, component: QuizReview },
  { path: CONFIG.NO_AGENT, component: NoAgentFound },
  { path: CONFIG.TROPHY_ROOM, component: TrophyRoom },
  { path: CONFIG.BADGES, component: BadgePage },
  { path: CONFIG.NOTIFICATIONS, component: AllNotifications },
  { path: CONFIG.USER_REPORT, component: UserReport },
  { path: CONFIG.SLS_CONFIRM, component: SLSConfirm },

];

export const loginWidgetRoutes = [
  { path: CONFIG.LOGIN_WIDGET_HOME, component: LoginWidget },
  { path: CONFIG.QUIZ_REVIEW, component: QuizReview },
  { path: CONFIG.NO_AGENT, component: NoAgentFound },
  { path: CONFIG.TROPHY_ROOM, component: TrophyRoom },
  { path: CONFIG.BADGES, component: BadgePage },
  { path: CONFIG.NOTIFICATIONS, component: AllNotifications },
  { path: CONFIG.USER_REPORT, component: UserReport },
  // { path: CONFIG.WIDGET_LOGIN_PAGE, component: WidgetLoginPage },
];

export const teamsRoutes = [
  { path: CONFIG.DISSCUSSION, component: Discussion },
  { path: CONFIG.DISSCUSSION_DETAILS, component: DiscussionDetail },
]

export const chatDiscussionRoutes = [
  { path: CONFIG.CHAT_DISSCUSSION, component: Discussion },
  { path: CONFIG.CHAT_DISSCUSSION_DETAILS, component: DiscussionDetail },
]
