export const IntentIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2578 3H3.74701L3.66498 5.46094H4.14349C4.40326 3.91602 4.90228 3.67676 6.83685 3.61523L7.26068 3.60156V11.4287C7.26068 12.0918 7.11713 12.249 5.96185 12.3516V12.8711H10.0429V12.3516C8.8808 12.249 8.73724 12.0918 8.73724 11.4287V3.60156L9.16791 3.61523C11.1025 3.67676 11.6015 3.91602 11.8613 5.46094H12.3398L12.2578 3Z"
      fill="currentColor"
    />
  </svg>
);

export const DocumentIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 3.32763C1.88498 2.95786 3.1539 2.55951 4.38768 2.43506C5.71799 2.30088 6.8464 2.49815 7.5 3.18677V12.9326C6.56511 12.4026 5.38065 12.3298 4.28732 12.4401C3.10693 12.5592 1.91771 12.9012 1 13.2511V3.32763ZM8.5 3.18677C9.1536 2.49815 10.282 2.30088 11.6123 2.43506C12.8461 2.55951 14.115 2.95786 15 3.32763V13.2511C14.0823 12.9012 12.8931 12.5592 11.7127 12.4401C10.6194 12.3298 9.43489 12.4026 8.5 12.9326V3.18677ZM8 2.28312C7.01509 1.43653 5.58683 1.30904 4.28732 1.44011C2.77322 1.59283 1.24459 2.11241 0.293099 2.5449C0.114601 2.62604 0 2.80401 0 3.00009V14.0001C0 14.1701 0.0863761 14.3284 0.229307 14.4205C0.372238 14.5125 0.55214 14.5256 0.706901 14.4553C1.58875 14.0544 3.01012 13.574 4.38768 13.4351C5.79565 13.293 6.97747 13.5223 7.60957 14.3124C7.70445 14.431 7.84811 14.5001 8 14.5001C8.15189 14.5001 8.29555 14.431 8.39043 14.3124C9.02253 13.5223 10.2043 13.293 11.6123 13.4351C12.9899 13.574 14.4113 14.0544 15.2931 14.4553C15.4479 14.5256 15.6278 14.5125 15.7707 14.4205C15.9136 14.3284 16 14.1701 16 14.0001V3.00009C16 2.80401 15.8854 2.62604 15.7069 2.5449C14.7554 2.11241 13.2268 1.59283 11.7127 1.44011C10.4132 1.30904 8.98491 1.43653 8 2.28312Z"
      fill="currentColor"
    />
  </svg>
);
