import React, { useEffect, useRef } from "react";

import ImagePreview from "../ImagePreview";
import StickerPreview from "../StickerPreview";
import VideoPreview from "../VideoPreview";

import LoadingAnimation from "../LoadingAnimation";
import "./style.css";

import { useTranslation } from "react-i18next";
import infoCirclekImg from "../../assets/images/info-circle-red.svg";
import bookmarkIcon from "../../assets/images/svg/bookmark-update.svg";
import bookmarkActiveIcon from "../../assets/images/svg/bookmark-active-update.svg";
import dotsBlue from "../../assets/images/svg/dots-h-blue.svg";
import flagFilled from "../../assets/images/svg/Flag-Filled.svg";
import flagRed from "../../assets/images/svg/flag-outline-red.svg";
import thumbsDownBlue from "../../assets/images/svg/thumbs-down-update.svg";
import thumbsDownRed from "../../assets/images/svg/thumbs-down-active-update.svg";
import thumbsupBlue from "../../assets/images/svg/thumbs-up-update.svg";
import thumbsUpGreen from "../../assets/images/svg/thumbs-up-active-update.svg";
import thumpsDownFilled from "../../assets/images/svg/thumbs-down-active-update.svg";
import thumpsUpFilled from "../../assets/images/svg/thumbs-up-active-update.svg";

import thumbsupImg from "../../assets/images/svg/thumbs-up-outline.svg";
import thumbsupDarkImg from "../../assets/images/svg/thumbs-up-dark.svg";
import thumbsDownImg from "../../assets/images/svg/thumbs-down-outline.svg";
import thumbsDownDarkImg from "../../assets/images/svg/thumbs-down-dark.svg";
import bookmarkImg from "../../assets/images/svg/bookmark-outline.svg";
import bookmarkDarkImg from "../../assets/images/svg/bookmark-dark.svg";
import flagImg from "../../assets/images/svg/flag-outline-red.svg";
import referenceCloseButton from "../../assets/images/svg/refenceclosebutton.svg";
import referenceCloseButtonActive from "../../assets/images/svg/refenceclosebuttonactive.svg";

import { useState } from "react";
import { useAuth } from "../../context/auth";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";

const BotReply = ({
  replyType,
  data,
  replyTime,
  replyId,
  documentId,
  bookmarkAction,
  showFeedback,
  feedback,
  recordFeedback,
  bubbleIndex,
  avatarIcon,
  documentPreviewSetting,
  setDocumentPreview,
  element,
  feedbackType,
  showFallback,
  isTyping = false,
  isShowFb = true,
  onSizeChange,
  lalestChat,
  isLoadingTyping=false,
  showReferences
}) => {
  const makeApiCall = useFetch();
  const { t } = useTranslation("translation");
  const [showFavBar, setShowFavBar] = useState(false);
  const [localFeedback, setLocalFeedback] = useState(null);
  const [bookmarkFeedback, setBookmarkFeedback] = useState(null);
  const [showFeedbackIcons, setshowFeedbackIcons] = useState(true);
  const [showThumpsdownError, setShowThumpsdownError] = useState(false);
  const [flagState, setflagState] = useState(false);
  const [docPreviews, setDocPreviews] = useState([]);
  const [isLoadingDocs, setLoadingDocs] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const booksmarksbarRef = useRef(null);
  const booksmarksbarRef2 = useRef(null);
  const { agentDisclaimer } = useAuth();
  const [isReferencesShown, setReferencesShown] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const onDocPreview = (doc) => {
    setDocumentPreview(doc, replyId, docPreviews);
  }

  const generateContent = () => {
    if (docPreviews.length !== 0) {
      setReferencesShown(!isReferencesShown);
      return;
    }
    // `${window.CHAT_API}/api/v1/chatbot/replies/${props.replyId}/references/${selectedDoc.id}/preview`,
    setLoadingDocs(true);
    makeApiCall
      .request(
        `${window.CHAT_API}/api/v1/chatbot/replies/${replyId}/bubbles/${data.id}/references`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setDocPreviews(json.data.references);
        } else {
          if (json.status && json.status.message) {
            setSnackbar({
              isOpen: true,
              message: "Oops! " + json.status.message,
              type: "error",
            });
          }
        }
        setLoadingDocs(false);
      });
  };

  const containerRef = useRef();

  // const [showFallback, setShowFallback] = useState(null);
  const botReplyDivRef = React.useRef(null);
  React.useEffect(() => {
    if (botReplyDivRef && botReplyDivRef.current) {
      // botReplyDivRef.current.scrollIntoView();
    }
  }, []);
  const [showBookmarkBar, setshowBookmarkBar] = useState(false);

  useEffect(() => {
    if (feedbackType && feedbackType.hasOwnProperty("type")) {
      setLocalFeedback(feedbackType.type);
    }
  }, []);

  useEffect(() => {
    if (data && data.html && onSizeChange){
      onSizeChange();
    }
  }, [data?.html]);

  useEffect(() => {
    if (window.innerWidth && window.innerWidth < 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window]);

  const isViewSource =
    data?.template === "text" &&
    ((documentPreviewSetting &&
      documentPreviewSetting?.allowed_channels &&
      documentPreviewSetting.allowed_channels?.includes("webchat")) ||
      data?.document_preview);

  useEffect(() => {
    if(data?.reply_id && data?.id && isLoadingTyping==false && element?.context?.botmode !== "improvising" && data?.document_preview !== 'intent' && isViewSource){
        generateContent()
    }
  }, [data,isLoadingTyping]);

  const getBotReplyItem = () => {
    if (data?.template === "text") {
      return (
        <>
          {!element ||
            (element &&
              ((element.context && element.context.botmode !== "improvising") ||
                !element.context) && (
                <>
                  <div
                    className={
                      "text-reply-container first-bubble position-relative" +
                      (isViewSource ? " view-srouce" : "")
                    }
                    onClick={() => {
                      setShowFavBar(!showFavBar);
                      // if (isViewSource)
                      //   setReferencesShown(!isReferencesShown);
                      //   generateContent();
                      }
                    }
                    role="alert"
                  >
                    {isTyping ? (
                      <LoadingAnimation />
                    ) : (
                      <>{data?.html && <div
                        className="text-reply text-reply-list"
                        dangerouslySetInnerHTML={{ __html: data.html }}
                      />}{isLoadingTyping && <span className="blink">-</span>}
                      </>
                    )}
                    {/* </div> */}
                    
                  </div>
                  
                </>
              ))}
          {/* new chat reply */}
          {/* senario 1 */}
          {element &&
            element.context &&
            element.context.botmode === "improvising" && (
              <>
                <div
                  className={
                    isMobile ? "text-reply-new flex-wrap" : "text-reply-new"
                  }
                >
                  <div
                    className="text-reply w-100 d-flex flex-column text-reply-list"
                    onClick={() => {
                      if (isMobile) {
                        setShowFavBar(!showFavBar);
                      }
                    }}
                    dangerouslySetInnerHTML={
                      flagState && data?.html
                        ? {
                          __html:
                            "Thank you for flagging this message as inappropriate. Our team will review it and take appropriate action.",
                        }
                        : 
                        { __html: `${data.html}` }
                    }
                  ></div>

                  {showFeedback && agentDisclaimer?.defaultDisclaimer && (
                    <div className="chatInfo-toltip position-relative">
                      <button className="btn px-2">
                        <img src={infoCirclekImg} alt=" " />
                      </button>
                      <div className="toltip-description">
                        {agentDisclaimer?.defaultDisclaimer}
                      </div>
                    </div>
                  )}

                  {/* && !showFallback  */}

                  {showFeedback &&
                    showFavBar &&
                    isMobile &&
                    !flagState &&
                    replyId && (
                      <>
                        <div className="bot-reply-feedback-container">
                          <span
                            className={`bot-reply-feedback-icon${localFeedback === 1
                                ? " bot-reply-feedback-icon-selected"
                                : ""
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFeedback(e, 1, replyId);
                              setshowFeedbackIcons(false);
                              setShowThumpsdownError(false);
                            }}
                          >
                            <button className="btn" title={t("like")} aria-label={t("like")}>
                              <img
                                src={
                                  localFeedback === 1
                                    ? thumpsUpFilled
                                    : thumbsupBlue
                                }
                                alt=" "
                              />
                            </button>
                          </span>
                          <span
                            className={`bot-reply-feedback-icon${localFeedback === 0
                                ? " bot-reply-feedback-icon-selected"
                                : ""
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFeedback(e, 0, replyId);
                              setshowFeedbackIcons(false);
                              setShowThumpsdownError(true);
                            }}
                          >
                            <button className="btn" title={t("unlike")} aria-label={t("unlike")}>
                              <img
                                src={
                                  localFeedback === 0
                                    ? thumpsDownFilled
                                    : thumbsDownBlue
                                }
                                alt=" "
                              />
                            </button>
                          </span>
                          <span
                            className={`bot-reply-feedback-icon${localFeedback === 3
                                ? " bot-reply-feedback-icon-selected"
                                : ""
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFeedback(e, 3, replyId);
                              setflagState(true);
                            }}
                          >
                            <button className="btn" aria-label={t("flag")} title={t("flag")}>
                              <img
                                src={localFeedback === 3 ? flagFilled : flagRed}
                                alt=" "
                              />
                            </button>
                          </span>
                          <span
                            className={`bot-reply-feedback-icon${feedback === 0
                                ? " bot-reply-feedback-icon-selected"
                                : ""
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              bookmarkAction("bot", data.text);
                            }}
                          >
                            <button className="btn" aria-label={t("bookmark")}>
                              <img src={feedback === 0 ? bookmarkActiveIcon : bookmarkIcon} alt=" " />
                            </button>
                          </span>
                        </div>
                      </>
                    )}

                  {/* {showFeedback && !flagState && !isMobile && (
                    <div className="borkmark-action-right">
                      <span
                        className={`bot-reply-feedback-icon${feedback === 0
                            ? " bot-reply-feedback-icon-selected"
                            : ""
                          }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setBookmarkFeedback(true);
                          bookmarkAction("bot", data.text);
                        }}
                      >
                        <button className="btn" aria-label={t("bookmark")}>
                          <img src={feedback === 0 ? bookmarkActiveIcon : bookmarkIcon} alt=" " />
                        </button>
                      </span>
                      <div className="three-dots-feedback">
                        <button
                          className="btn more"
                          title={t("more")}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              booksmarksbarRef2.current.classList.toggle("shown")
                            }
                          }}
                        >
                          <img src={dotsBlue} alt=" " />
                        </button>

                        <div className="showBookmarks" ref={booksmarksbarRef2}>
                          <span
                            className={`bot-reply-feedback-icon${localFeedback === 1
                                ? " bot-reply-feedback-icon-selected"
                                : ""
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFeedback(e, 1, replyId);
                            }}
                          >
                            <button className="btn">
                              <img
                                src={
                                  localFeedback === 1
                                    ? thumpsUpFilled
                                    : thumbsupBlue
                                }
                                alt=" "
                              />
                            </button>
                          </span>
                          <span
                            className={`bot-reply-feedback-icon${localFeedback === 0
                                ? " bot-reply-feedback-icon-selected"
                                : ""
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFeedback(e, 0, replyId);
                            }}
                          >
                            <button className="btn">
                              <img
                                src={
                                  localFeedback === 0
                                    ? thumpsDownFilled
                                    : thumbsDownBlue
                                }
                                alt=" "
                              />
                            </button>
                          </span>
                          <span
                            className={`bot-reply-feedback-icon${localFeedback === 3
                                ? " bot-reply-feedback-icon-selected"
                                : ""
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFeedback(e, 3, replyId);
                              setflagState(true);
                            }}
                          >
                            <button className="btn" aria-label={t("flag")} title={t("flag")}>
                              <img
                                src={localFeedback === 3 ? flagFilled : flagRed}
                                alt=" "
                              />
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {showFeedback &&
                    isShowFb &&
                    !showBookmarkBar &&
                    showFallback &&
                    !flagState && (
                      <div className="action-wraper d-flex align-items-center">
                        <div className="like-msgs">
                          {/*{showFeedbackIcons && !showFavBar && (
                            <span>Was this response useful?</span>
                          )}*/}
                          {!showFeedbackIcons && !showThumpsdownError && (
                            <>
                              <span className="d-flex align-items-center">
                                <img
                                  src={thumbsUpGreen}
                                  alt=""
                                  className="mr-2"
                                />{" "}
                                Great, glad we could help!
                              </span>
                            </>
                          )}
                          {!showFeedbackIcons && showThumpsdownError && (
                            <span className="d-flex align-items-center">
                              <img
                                src={thumbsDownRed}
                                alt=""
                                className="mr-2"
                              />{" "}
                              Your feedback is appreciated and we'll use it to
                              improve our service.
                            </span>
                          )}
                        </div>
                        {/*{showFeedbackIcons && !showFavBar && (
                          <>
                            <div className="like-btns-wrap d-flex align-items-center">
                              <span
                                className={`bot-reply-feedback-icon${localFeedback === 1
                                    ? " bot-reply-feedback-icon-selected"
                                    : ""
                                  }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFeedback(e, 1, replyId);
                                  setshowFeedbackIcons(false);
                                }}
                              >
                                <button className="btn" aria-label={t("like")}>
                                  <img src={thumbsupBlue} alt=" " />
                                </button>
                              </span>
                              <span
                                className={`bot-reply-feedback-icon${localFeedback === 0
                                    ? " bot-reply-feedback-icon-selected"
                                    : ""
                                  }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFeedback(e, 0, replyId);
                                  setshowFeedbackIcons(false);
                                  setShowThumpsdownError(true);
                                }}
                              >
                                <button className="btn" aria-label={t("unlike")}>
                                  <img src={thumbsDownBlue} alt=" " />
                                </button>
                              </span>
                              <span
                                className={`bot-reply-feedback-icon${localFeedback === 3
                                    ? " bot-reply-feedback-icon-selected"
                                    : ""
                                  }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFeedback(e, 3, replyId);
                                  setflagState(true);
                                }}
                              >
                                <button className="btn" aria-label={t("flag")}>
                                  <img
                                    src={
                                      localFeedback === 3 ? flagFilled : flagRed
                                    }
                                    alt=" "
                                  />
                                </button>
                              </span>
                              {isMobile && (
                                <span
                                  className={`bot-reply-feedback-icon${feedback === 0
                                      ? " bot-reply-feedback-icon-selected"
                                      : ""
                                    }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    bookmarkAction("bot", data.text);
                                  }}
                                >
                                  <button className="btn" aria-label={t("bookmark")}>
                                    <img src={feedback === 0 ? bookmarkActiveIcon : bookmarkIcon} alt=" " />
                                  </button>
                                </span>
                              )}
                            </div>
                          </>
                        )}*/}
                      </div>
                    )}
                </div>
              </>
            )}
        </>
      );
    } else if (data?.template === "image" && data.image) {
      return <ImagePreview src={data.image.url} altText={data.text} />;
    } else if (data?.template === "image" && !data.image) {
      return data?.html && <ImagePreview html={data.html} altText={data.text} />;
    } else if (data?.template === "video") {
      return data?.html && <VideoPreview link={data.video.url} html={data.html} />;
    } else if (data?.template === "sticker") {
      return <StickerPreview src={data.image.url} altText={t("sticker")} />;
    }
  };

  const handleFeedback = (event, feedbackType, replyId) => {
    recordFeedback(feedbackType, replyId);
    setLocalFeedback(feedbackType);
    let allSiblings = event.target.parentNode
      ? event.target.parentNode.childNodes
      : [];

    if (allSiblings) {
      allSiblings.forEach((element) => {
        if (
          element.classList &&
          element.classList.contains("bot-reply-feedback-icon-selected")
        ) {
          element.classList.remove("bot-reply-feedback-icon-selected");
        }
      });
    }

    event.target.classList.add("bot-reply-feedback-icon-selected");
  };

  if (replyType === "loading") {
    return (
      <div className="bot-reply-wrapper">
        <div className="bot-bubble">
          <div className="bot-chat-avatar-container">
            <img className="bot-avatar-img" src={avatarIcon} />
          </div>
          <div className="loading-bubble">
            <LoadingAnimation />
          </div>
        </div>
      </div>
    );
  }

  // if (isTyping === "") {
  //   return (
  //     <div className="bot-reply-wrapper">
  //       <div className="bot-bubble">
  //         <div className="bot-chat-avatar-container">
  //           <img className="bot-avatar-img" src={avatarIcon} />
  //         </div>
  //         <div className="loading-bubble">
  //           <LoadingAnimation />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  if (
    !showFallback &&
    element &&
    element.context &&
    element.context.botmode === "improvising" &&
    element.feedback &&
    element.feedback.type === 3
  ) {
    return null;
  }

  return (
    <div className="bot-reply-wrapper" ref={botReplyDivRef}>
      <div className="bot-bubble">
        <div className="bot-chat-avatar-container">
          <img className="bot-avatar-img" src={avatarIcon} />
        </div>
        <div className="bot-reply-container" ref={containerRef}>
          {getBotReplyItem()}
          {isReferencesShown && docPreviews.length > 0 && <div className="reference-container">
            <div>References: </div>
            {
              docPreviews.map((doc) => <div className="doc-item" key={doc.index}>
                <span className="mr-1">{doc.index}.</span>
                <button onClick={() => onDocPreview(doc)}>{doc.name}</button>
              </div>)
            }
          </div>}
          
          <div>
          {/* {isViewSource && !isReferencesShown && lalestChat === replyId && !isLoadingTyping && (
          //   <div
          //     className="bot-document-preview-button d-flex align-items-center"
          //     onClick={() => { generateContent() }}
          //   >
          //     {isLoadingDocs && <span className="reference-loader"></span>}
          //     {t("View References")}
          //   </div>
          // )} */}
          {/* {isViewSource && isReferencesShown && (
            <div
              className="bot-document-preview-button d-flex align-items-center"
              onClick={() => { setReferencesShown(false) }}
            >
               {t("Hide References")}
            </div>
          )} */}
          
          </div>
          
        </div>
        
      </div>
      {/* {showFeedback &&
          !showBookmarkBar &&
          showFallback &&
          !flagState &&
          isMobile ? (
          <div className="pl-12"></div>
        ) : (
          <div className="pl-12">{replyTime}</div>
        )} */}
        {/* {lalestChat !== replyId ? */}
         {/* {showFeedback && !isMobile && !isLoadingTyping && data.template === "text" && ( */}
          {showFeedback && !isLoadingTyping && data?.template === "text" && (
            <div className="pl-12 flex space-x-3 absolute z-1 -pt-100  ">
              <button
                className="btn"
                title={t("bookmark")}
                aria-label={t("bookmark")}
                onClick={(e) => {
                  e.stopPropagation();
                  setBookmarkFeedback(true);
                  bookmarkAction("bot", data.text);
                }}
              >
                <img src={feedback === 0 ? bookmarkActiveIcon : bookmarkIcon} alt=" " />
              </button>
          
              <button
                className="btn"
                title={t("like")}
                aria-label={t("like")}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFeedback(e, 1, replyId);
                }}
              >
                <img
                  src={
                    localFeedback === 1
                      ? thumpsUpFilled
                      : thumbsupBlue
                  }
                  alt=" "
                />
              </button>
          
              <button
                className="btn"
                title={t("unlike")}
                aria-label={t("unlike")}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFeedback(e, 0, replyId);
                }}
              >
                <img
                  src={
                    localFeedback === 0
                      ? thumpsDownFilled
                      : thumbsDownBlue
                  }
                  alt=" "
                />
              </button>

              { element.context.botmode === "improvising" && <button
                className="btn"
                title={t("flag")}
                aria-label={t("flag")}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFeedback(e, 3, replyId);
                  setflagState(true);
                }}
              >
                <img
                src={localFeedback === 3 
                  ? flagFilled 
                  : flagRed}
                  alt=" "
                />
              </button>}
              {element.context.botmode !== "improvising" && data?.document_preview !== 'intent' && (isViewSource && docPreviews.length > 0 ? true : false) &&
                <button
                  className="btn"
                  title={t("view reference")}
                  aria-label={t("view reference")}
                  onClick={() => { setReferencesShown(!isReferencesShown); generateContent() }}
                >
                  <img
                    src={isViewSource && !isReferencesShown ? referenceCloseButton:isViewSource && isReferencesShown ? referenceCloseButtonActive:referenceCloseButton}
                    // src={isReferencesShown ?referenceCloseButtonActive:referenceCloseButton}
                    alt=" "
                  />
                </button>
              }
            </div>
            )
          }
          {/* :showFeedback && !isMobile && (
            <div className="pl-12 flex space-x-3 absolute -pt-100">
              <button
                className="btn"
                title={t("bookmark")}
                aria-label={t("bookmark")}
                onClick={(e) => {
                  e.stopPropagation();
                  setBookmarkFeedback(true);
                  bookmarkAction("bot", data.text);
                }}
              >
                <img src={feedback === 0 ? bookmarkActiveIcon : bookmarkIcon} alt=" " />
              </button>
              <button
                className="btn"
                title={t("like")}
                aria-label={t("like")}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFeedback(e, 1, replyId);
                }}
              >
                <img
                  src={
                    localFeedback === 1
                      ? thumpsUpFilled
                      : thumbsupBlue
                  }
                  alt=" "
                />
              </button>
          
              <button
                className="btn"
                title={t("unlike")}
                aria-label={t("unlike")}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFeedback(e, 0, replyId);
                }}
              >
                <img
                  src={
                    localFeedback === 0
                      ? thumpsDownFilled
                      : thumbsDownBlue
                  }
                  alt=" "
                />
              </button>
          
              <button
                className="btn"
                title={t("view reference")}
                aria-label={t("view reference")}
                onClick={() => { setReferencesShown(!isReferencesShown); generateContent() }}
              >
                <img
                  src={isViewSource && !isReferencesShown ? referenceCloseButton:isViewSource && isReferencesShown ? referenceCloseButtonActive:referenceCloseButton}
                  alt=" "
                />
              </button>
            </div>
    
            )*/}        
    </div>
    
  );
};

export default BotReply;
