import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import NotificationItem from "../../components/NotificationItem";
import NoDataScreen from "../../components/NoDataScreen";

import { useTranslation } from "react-i18next";

const NotificationPanel = (props) => {
  const { t } = useTranslation("translation");
  const [Overlay, setOverlay] = useState(false);
  let history = useHistory();

  const closeNotificationPanel = () => {
    props.setNotificationToggle(false);
    let rightPanel = document.getElementById("notification-panel");
    if (rightPanel) {
      rightPanel.classList.remove("open-panel-wrap");
    }
  };

  useEffect(() => {
    if (props.notificationToggle) {
      setTimeout(() => {
        setOverlay(props.notificationToggle);
      }, 250);
    } else {
      setOverlay(false);
    }
  }, [props.notificationToggle]);

  return (
    <>
      <div id="notification-panel" className="panel-wrap">
        <div className="panel pb-0">
          <div className="notification-panel-header flex-column p-0">
            <div className="flex-container py-3 px-4 border-bottom align-items-center">
              <button
                className="close-button pr-3"
                onClick={closeNotificationPanel}
                title={t("Close")}
              >
                <i className="icon-left-chevron" />
              </button>
              <div>{t("Notification")}</div>
              <span className="notification-count">
                {props.notificationCount}
              </span>
            </div>

            
          </div>

          <div className="notification-lists auto-scroll">
            {props.allNotifications.map((notification, index) => (
              <NotificationItem
                showNotificationButtons
                showPointer={notification.link ? true : false}
                key={notification._id}
                content={notification}
                onClick={() =>
                  props.onClick(notification.link, notification._id)
                }
                onDelete={(event) => props.onDelete(event, notification._id)}
                onCheckboxChange={(event) =>
                  props.onCheckboxChange(
                    event,
                    notification._id,
                    notification.is_read
                  )
                }
                onCustomButtonClick={props.onCustomButtonClick}
                closeNotificationPanel={closeNotificationPanel}
              ></NotificationItem>
            ))}
            {props.allNotifications.length == 0 && (
              <NoDataScreen
                message={t("You don’t have any notifications.")}
              ></NoDataScreen>
            )}
          </div>
          {/* <button
            className="send-notification-btn"
            onClick={() => history.push("/all-notifications")}
            title={t("Send Notification")}
          >
            {t("See all notifications")}
          </button> */}
          <div>
              {/* <button
                className="see-all-button"
                onClick={() => showNotificationPage(0)}
                title="See all notification"
              >
                See all notifications
              </button> */}
              {/* {props.allNotifications.length > 0 && ( */}
                <div className="notification-action-container">
                  <div className="w-50">
                    <button
                      className="mark-read-button w-100"
                      onClick={props.markAllRead}
                      title={t("Mark all as read")}
                    >
                      {t("Mark as read")}
                    </button>
                  </div>
                  <div className="w-50">
                    <button
                      className="mark-read-button w-100 clear-btn"
                      onClick={props.clearAll}
                      title={t("Clear all")}
                    >
                      {t("Clear all")}
                    </button>
                  </div>
                </div>
              {/* )} */}
            </div>
        </div>
      </div>
      {/* {Overlay && (
        <div className="sidebar-overlay" onClick={closeNotificationPanel}></div>
      )} */}
    </>
    // {notificationToggle && (
    // )}
  );
};

export default NotificationPanel;
