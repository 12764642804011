import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth0 } from "../../react-auth0-spa";
import { useAuth } from "../../context/auth";
import { v4 as uuidv4 } from "uuid";
import Moment from "react-moment";
import { fetchEventSource } from "@microsoft/fetch-event-source";

import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";
import {
  getNRandomArrayIndices,
  getUrlParameter,
} from "../../helpers/formatFunctions";
import { sleep, useInterval } from "../../helpers/timer";
import AgentClassService from "../../helpers/AgentClassService";
import throttle from "lodash/throttle";
import ChatInput from "../../components/ChatInput";
import BotChatBubble from "../../components/BotChatBubble";
import UserChatBubble from "../../components/UserChatBubble";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import SuggestionButton from "../../components/SuggestionButton";
import Snackbar from "../../components/Snackbar";
import DocumentPreview from "../../components/DocumentPreview";
import Form from "../../components/Form";
import FormSubmissionMessage from "../../components/FormSubmissionMessage";
import QuizGenerator from "../../components/QuizGenerator";
import QuizReport from "../../components/QuizReport";
import ChatFeaturePanel from "../ChatFeaturePanel";
import IntroPanel from "../../components/IntroPanel";
import ChatHeader from "../../components/ChatHeader";
import NotificationPanel from "./notificationPanel";
import BotReply from "../../components/BotReply";
import PastSessionPanel from "../PastSessionPanel";
import RatingInput from "../../components/RatingInput";
import AgentSelection from "../AgentSelection";
import ResumeSessionModal from "../ResumeSessionModal";
import BotQuizBubble from "../../components/BotQuizBubble";
import BotQuizResultBubble from "../../components/BotQuizResultBubble";
import PopupNotification from "../../components/PopupNotification";
import DefaultPeepingFace from "../../assets/images/default-peeping-face.png";
import WalterLeftHand from "../../assets/images/walter-left-hand.png";
import WalterRightHand from "../../assets/images/walter-right-hand.png";
import WalterSideHand from "../../assets/images/walter-side-hand.png";
import { getUserAvatarIcon } from "./data";
import { linkify, prettifyDate } from "../../helpers/formatFunctions";

import NoodleLogo from "../../assets/images/logo-horizontal.png";
import AvatarIcon from "../../assets/images/walter-head-white.png";

import "./style.css";
import "./widget-style.css";
import ChatFeedback from "../../components/ChatFeedback";
import WidgetLoginPage from "../WidgetLoginPage";
import { useTranslation } from "react-i18next";
import Allbookmarks from "../AllBookmarks";
import AllSessions from "../AllSessions";
import UserReport from "../UserReport";
import { useStateRef } from "../../helpers/useStateRef";
import ButtonPaging from "../../components/ButtonPaging";
import MultiDocumentPreview from "../../components/MultiDocumentPreview";
import PromptsIdeaIcon from "../../assets/images/svg/suggested-prompts-bulb-img.svg";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import AllAnswerQuestions from "../AllAnswerQuestions";
import QueueService from "../../helpers/QueueService";
import OrganisationSwitcher from "../OrganisationSwitcher/OrganisationSwitcher";

const LoginWidget = (props) => {
  const { t } = useTranslation("translation");
  const queueRef = useRef(new QueueService());
  const queueService = queueRef.current;
  const chatInputRef = useRef();
  const {
    authTokens,
    setAuthTokens,
    showAgentSelection,
    setShowAgentSelection,
    selectedClass,
    setSelectedClass,
    showOrgSelection,
    agentProfileImage
  } = useAuth();

  const { logout, getTokenSilently } = useAuth0();
  const [feedbackMap, setFeedbackMap] = useState({});
  const [displayedQuestionsIdConvo, setDisplayedQuestionsIdConvo] = useState(
    []
  );
  const [feedbackComment, setFeedbackComment] = useState("");
  const [sessionFeedbackComment, setSessionFeedbackComment] = useState("");
  const [feedbackId, setFeedbackId] = useState("");
  const lastLog = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const [docPreviews, setDocPreviews] = useState([]);
  const [enableSearch, setEnableSearch] = useState(false);

  const [isSseClose, setIsSseClose] = useState(true);
  const [isWaitRender, setIsWaitRender] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackContainer, setOpenFeedbackContainer] = useState(false);
  const [suggestedQuestionsData, setSuggestedQuestionsData] = useState([]);
  // const [chatData, setChatData] = useState([]);
  const [chatData, setChatData, chatDataRef] = useStateRef([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [textBlockIds, setTextBlockIds] = useState({
    current: "",
    previous: "",
    next: "",
  });
  const [documentIdForDocumentPreview, setDocumentIdForDocumentPreview] =
    useState("");
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [form, setForm] = useState({});
  const [showFormSubmissionMessage, setShowFormSubmissionMessage] =
    useState(false);
  const [formSubmissionMessageDetails, setFormSubmissionMessageDetails] =
    useState({
      heading: "",
      message: "",
      buttonLabel: "",
      type: "",
    });

  const [messageCopy, setMessageCopy] = useState("");
  const [showQuiz, setShowQuiz] = useState(false);
  const [quiz, setQuiz] = useState({});
  const [disableQuizButton, setDisableQuizButton] = useState(false);
  const [showQuizReport, setShowQuizReport] = useState({
    open: false,
    type: "success",
    data: {},
  });
  const [title, setTitle] = useState("Chat");
  const [chatContext, setChatContext] = useState("");

  // Settings variables
  const [companyLogo, setCompanyLogo] = useState(NoodleLogo);
  const [sidebarMessage, setSidebarMessage] = useState(
    "<p>Hi there! 👋</p><p>I am Walter, your smart chat buddy.<br></p>"
  );
  const [botAvatar, setBotAvatar] = useState(AvatarIcon);
  const [botName, setBotName] = useState("Walter");
  const [botDelay, setBotDelay] = useState(2000);
  const [customButtonData, setCustomButtonData] = useState([]);

  const [notificationToggle, setNotificationToggle] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [suggestionsExpanded, setSuggestionsExpanded] = useState(false);
  const [showHomePanel, setShowHomePanel] = useState(false);
  const [hideChatInput, setHideChatInput] = useState(false);
  const [buttonReference, setButtonReference] = useState("");
  const [logoutAfterFeedback, setLogoutAfterFeedback] = useState(false);
  const [sessionFeedback, setSessionFeedback] = useState(0);
  const [openSessionEndModal, setOpenSessionEndModal] = useState(false);
  const [pastSessionData, setPastSessionData] = useState([]);
  const [enableLearning, setEnableLearning] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [sessionTime, setSessionTime] = useState(0);
  const [triggerBookmarkLoad, setTriggerBookmarkLoad] = useState(false);
  const [quizDisplayedQuestions, setQuizDisplayedQuestions] = useState([]);
  const [agentsMap, setAgentsMap] = useState({});
  const [selectedAgent, setSelectedAgent] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  const [activeSession, setActiveSession] = useState({});
  const [showDocumentPreview, setShowDocumentPreview] = useState(false);
  const [answerForDocumentPreview, setAnswerForDocumentPreview] = useState("");
  const [replyIdForDocumentPreview, setReplyIdForDocumentPreview] =
    useState("");
  const [documentPreviewContent, setDocumentPreviewContent] = useState("");
  // const [isAddSpace, setIsAddSpace] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(true);

  const [showResumeSessionModal, setShowResumeSessionModal] = useState(false);
  const [showNewSessionAgentSelection, setShowNewSessionAgentSelection] =
    useState(false);
  const [disableCustomButton, setDisableCustomButton] = useState(false);
  const [isConversationalQuizActive, setIsConversationalQuizActive] =
    useState(false);
  const [pastSessionActiveTab, setPastSessionActiveTab] = useState(false);
  const [popupNotificationData, setPopupNotificationData] = useState({});
  const [openPopupNotification, setOpenPopupNotification] = useState(false);
  const [toggleNoClassHistory, setToggleNoClassHistory] = useState(false);
  const [selectedSidebarOption, setSelectedSidebarOption] = useState("chat");
  const [widgetSettings, setWidgetSettings] = useState({
    headerName: "Walter",
    headerIcon: AvatarIcon,
    isTest: false,
    branding: true,
    homeScreenMessage: "",
    showPeepingImg: false,
    showPeepingImgHands: true,
    peepingImgPosition: "top",
    peepingImgCustom: "",
    widgetPosition: "right",
    buttonShape: "default",
  });
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);

  const [showPrompts, setShowPrompts] = useState(false);
  const [copiedSuggestedPrompts, setCopiedSuggestedPrompts] = useState([]);

  const [showTooltip, setShowTooltip] = useState(false);
  const [promptsMessage, setPromptsMessage] = useState(null);

  const chatMessagesRef = useRef(null);
  const historyChatRef = useRef(null);
  const currentQuiz = useRef(null);
  const convoQuizAnswers = useRef(null);
  const initialRender = useRef(true);
  const conversationalQuizStrategy = useRef("normal");

  const makeApiCall = useFetch();
  let history = useHistory();
  useInterval(() => {
    gaSendPing();
  }, 30000);

  useEffect(() => {
    if (agentProfileImage)
      getDefaultAgent("initial");
  }, [agentProfileImage]);

  useEffect(() => {
    getOrganisationName();
    checkAgentFeatureEnabled(
      "learning",
      () => {
        setEnableLearning(false);
      },
      () => {
        setEnableLearning(true);
      }
    );
    updateLocalSession();
    getNotifications();
    // getAllNotifications();
    if (!showForm) {
      updateScrollbar();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    chatMessagesRef.current = chatData;
    // updateScrollbar();
  }, [chatData]);

  useEffect(() => {
    chatMessagesRef.current = chatData;
    updateScrollbar();
  }, [chatMessagesRef.current, queueService.queue.length]);

  useEffect(() => {
    if (historyChatRef.current !== null) {
      setChatData(historyChatRef.current);
      updateScrollbar();
    }
  }, [historyChatRef]);

  useEffect(() => {
    updateScrollbar();
  }, [showForm === false]);

  useEffect(() => {
    updateScrollbar();
  }, [showQuiz === false]);

  useEffect(() => {
    if (messageCopy !== "" && messageCopy.trim() !== "") {
      sendMessage(messageCopy);
      updateScrollbar();
      setMessageCopy("");
    }
  }, [messageCopy]);

  useEffect(() => {
    if (promptsMessage) {
      setMessageCopy(promptsMessage.text);
    }
  }, [promptsMessage]);

  useEffect(() => {
    if (!initialRender.current) {
      getSuggestedQuestions();
      getSuggestedPrompts();
    }
  }, [chatContext]);

  // Show Suggested Prompts UseEffect
  useEffect(() => {
    getSuggestedPrompts();
  }, []);

  // start GA functions
  const gaSendPing = () => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });
      window.gtag("event", "active_session", {
        event_category: "session",
        event_label: activeSession._id,
      });
    }
  };

  const gaEndSession = () => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });

      window.gtag("event", "end_session", {
        event_category: "session",
        event_label: activeSession._id,
      });
    }
  };

  const gaClickButton = (button_type, button_label) => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });

      window.gtag("event", "click_button", {
        event_category: "conversation",
        event_label: activeSession._id + "::" + button_type,
        value: button_label,
      });
    }
  };
  // end GA functions

  const updateLocalSession = () => {
    getCurrentSession((ss) => {
      if (ss) {
        setActiveSession(ss);
        gaSendPing();
      }
    });
  };

  const getNotifications = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/summary",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          // setNotificationSummary(json.data.notifications);
          setAllNotifications(json.data.notifications);
          setNotificationCount(json.data.unread_count);
          if (
            json.data.notifications[0] &&
            json.data.notifications[0].is_popup &&
            !json.data.notifications[0].is_read
          ) {
            setPopupNotificationData(json.data.notifications[0]);
            setOpenPopupNotification(true);
            markNotificationAsRead(json.data.notifications[0]._id);
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Error fetching notifications",
            type: "error",
          });
        }
      });
  };

  const markNotificationAsRead = (id) => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + id,
        {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            is_read: true,
          }),
        },
        true
      )
      .then((json) => {});
  };

  const onClickNotification = (link, id) => {
    markNotificationAsRead(id);
    if (link !== "" && link !== null) {
      let strippedDomain = link.replace(/https?:\/\/[^\/]+/i, "");
      var localDomains = [
        "chatbot.staging.noodlefactory.ai",
        "chatbot.noodlefactory.ai",
        "localhost",
      ];
      var isLocalDomain = localDomains.some((element) =>
        link.includes(element)
      );

      if (isLocalDomain === true) {
        history.push(strippedDomain);
      } else {
        window.open(link, "_newtab");
      }
    }
  };

  const onDeleteNotification = (event, key) => {
    event.stopPropagation();
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "DELETE",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Unable to delete notification: " + json.status.message,
            type: "error",
          });
        }
      });
  };

  const onCheckboxChangeNotification = (event, key, isRead) => {
    event.stopPropagation();

    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            is_read: isRead ? false : true,
          }),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Unable to update notification: " + json.status.message,
            type: "error",
          });
        }
      });
  };

  const markAllRead = async () => {
    let notifications = [...allNotifications];

    await notifications.forEach((notification, i) => {
      if (notification.is_read === false) {
        makeApiCall
          .request(
            window.CHAT_API +
              "/api/v1/notification/notifications/" +
              notification._id,
            {
              method: "PATCH",
              headers: authHeader(),
              body: JSON.stringify({
                is_read: notification.isRead ? false : true,
              }),
            },
            true
          )
          .then((json) => {
            if (json.status.code === 200) {
            } else {
              setSnackbar({
                isOpen: true,
                message:
                  "Unable to update notification: " + json.status.message,
                type: "error",
              });
            }
          });
      }
    });

    getNotifications();
  };

  const clearAllNotification = async () => {
    let notifications = [...allNotifications];

    await notifications.forEach((notification, i) => {
      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/notification/notifications/" +
            notification._id,
          {
            method: "DELETE",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
          } else {
            setSnackbar({
              isOpen: true,
              message: "Unable to update notification: " + json.status.message,
              type: "error",
            });
          }
        });
    });

    getNotifications();
  };

  const onChatInputFocus = () => {
    // setSuggestionsExpanded(true);
  };

  const onChatInputBlur = () => {
    // setSuggestionsExpanded(false);
  };

  const callStartSessionApi = () => {
    let currentAgent = AgentClassService.getSelectedAgent();
    applyAgentSetting(agentsMap[currentAgent].settings);
    getGreetings(false, true);
  };

  const callResumeSessionApi = (session_id = "last") => {
    const requestOptions = {
      method: "PATCH",
      headers: authHeader(),
    };

    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/sessions/" + session_id,
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data && json.data.session) {
            setActiveSession(json.data.session);
          }
          setShowResumeSessionModal(false);
          fetchChat(json?.data?.session?._id);
          getLastFeedback();
          initialRender.current = false;
          getSuggestedQuestions();
          getSuggestedPrompts();
          updateLocalSession();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Session could not be resumed.",
            type: "error",
          });
        }
      });
  };

  const startFeedbackTimer = (lastFeedbackDate) => {
    let timeDelay = [600000, 900000, 1200000];
    let timeDelay2 = [1000, 5000, 10000];
    let today = new Date();
    let lastFeedback = new Date(lastFeedbackDate);

    today.setHours(0, 0, 0, 0);
    lastFeedback.setHours(0, 0, 0, 0);

    if (today > lastFeedback) {
      setTimeout(() => {
        setOpenFeedbackContainer(true);
      }, timeDelay[Math.floor(Math.random() * timeDelay.length)]);
    }
  };

  const getLastFeedback = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/feedbacks",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        let error = false;
        if (json && json.status.code === 200) {
          if (
            json.data &&
            json.data.session_feedback &&
            json.data.session_feedback[0]
          ) {
            startFeedbackTimer(json.data.session_feedback[0].created_at);
          }
        } else {
          error = true;
        }

        if (error) {
          setSnackbar({
            isOpen: true,
            message: "Error fetching feedback data",
            type: "error",
          });
        }
      });
  };

  const getSessions = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/sessions",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (
            json.data.sessions.length > 0 &&
            json.data.sessions[0].is_active
          ) {
            fetchChat(json.data.sessions[0].session_id);
          } else if (
            json.data.sessions.length > 0 &&
            !json.data.sessions[0].is_active
          ) {
            setShowResumeSessionModal(true);
          } else {
            getGreetings();
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Error fetching sessions",
            type: "error",
          });
        }
      });
  };

  const getSummary = (sessionId) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/learning/session-summary?session_id=" +
          sessionId,
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          setSummaryData(json.data.session_summary);
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const checkSelectedAgentAndClass = () => {
    if (AgentClassService.getSelectedAgent() && selectedClass) {
      callStartSessionApi();
      // setShowNewSessionAgentSelection(false);
      // getSuggestedQuestions();
    } else if (AgentClassService.getSelectedAgent()) {
      callStartSessionApi();
    } else {
      if (Object.keys(agentsMap).length === 1) {
        onAgentChange(Object.keys(agentsMap)[0], "new");
      } else {
        setShowAgentSelection(true);
      }
    }
  };

  const getClasses = (receivedClass, type, origin) => {
    let receivedSelectedClass = receivedClass;
    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/course/classes`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (receivedSelectedClass && origin === "params") {
            let temp = json.data.classes.filter(
              (classRecord) => classRecord._id === receivedSelectedClass
            );

            if (temp.length) {
              setSelectedClass(temp[0].connection_id);
              AgentClassService.setSelectedClass(temp[0].connection_id);
              if (type === "initial") {
                getLastFeedback();
                initialRender.current = false;
              }
              getSuggestedQuestions();
              getSuggestedPrompts();
            } else {
              receivedSelectedClass = "";
            }
          } else if (receivedSelectedClass) {
            let temp = json.data.classes.filter(
              (classRecord) =>
                classRecord.connection_id === receivedSelectedClass
            );

            if (temp.length) {
              setSelectedClass(receivedSelectedClass);
              AgentClassService.setSelectedClass(receivedSelectedClass);
              if (type === "initial") {
                getLastFeedback();
                initialRender.current = false;
              }
              getSuggestedQuestions();
              getSuggestedPrompts();
            } else {
              receivedSelectedClass = "";
            }
          }
          if (!receivedSelectedClass && json.data.classes.length > 0) {
            setSelectedClass(json.data.classes[0].connection_id);
            AgentClassService.setSelectedClass(
              json.data.classes[0].connection_id
            );
          }
          if (!showForm) {
            getSessions();
          }
        }
      });
  };

  const selectDefaultClass = () => {
    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/course/classes`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.classes.length > 0) {
            setSelectedClass(json.data.classes[0].connection_id);
            AgentClassService.setSelectedClass(
              json.data.classes[0].connection_id
            );
          }
        }
      });
  };

  const getDefaultAgent = (type) => {
    let allParams = getUrlParameter(window.location.search.split("?")[1]);
    if (
      "class" in allParams &&
      allParams.class &&
      "agent" in allParams &&
      allParams.agent &&
      type === "initial"
    ) {
      let receivedSelectedAgent = allParams.agent;
      let receivedSelectedClass = allParams.class;
      makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/agents", {
          method: "GET",
          headers: authHeader(),
        }, null, null, true)
        .then((json) => {
          if (json.status.code === 200) {
            if (json.data.agents.length === 0) {
              AgentClassService.setSelectedAgent("");
              setSelectedClass("");
              AgentClassService.setSelectedClass("");
              setSelectedAgent("");
              history.push("/no-agent-found");
            } else {
              if (receivedSelectedAgent) {
                let temp = json.data.agents.filter(
                  (agent) => agent._id === receivedSelectedAgent
                );
                if (temp.length) {
                  AgentClassService.setSelectedAgent(receivedSelectedAgent);
                  setSelectedAgent(receivedSelectedAgent);
                  getClasses(receivedSelectedClass, type, "params");
                } else {
                  receivedSelectedAgent = "";
                }
              }

              if (!receivedSelectedAgent && json.data.agents.length > 1) {
                setShowAgentSelection(true);
              } else {
                if (!receivedSelectedAgent) {
                  AgentClassService.setSelectedAgent(json.data.agents[0]._id);
                  setSelectedAgent(json.data.agents[0]._id);
                  getClasses(receivedSelectedClass, "", "params");
                  if (type === "initial") {
                    getLastFeedback();
                    initialRender.current = false;
                  }
                  getSuggestedQuestions();
                  getSuggestedPrompts();
                }

                let temp = {};
                json.data.agents.forEach((agent) => {
                  temp[agent._id] = agent;
                });
                setAgentsMap(temp);

                receivedSelectedAgent
                  ? applyAgentSetting(temp[receivedSelectedAgent].settings)
                  : applyAgentSetting(json.data.agents[0].settings);
              }
            }
          }
        });
    } else if (!("code" in allParams)) {
      makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/states", {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          if (json.status.code === 200) {
            let receivedSelectedAgent = json.data.states.selected_agent;
            let receivedSelectedClass = json.data.states.selected_class;

            makeApiCall
              .request(window.CHAT_API + "/api/v1/organisation/agents", {
                method: "GET",
                headers: authHeader(),
              }, null, null, true)
              .then((json) => {
                if (json.status.code === 200) {
                  if (json.data.agents.length === 0) {
                    AgentClassService.setSelectedAgent("");
                    setSelectedClass("");
                    AgentClassService.setSelectedClass("");
                    setSelectedAgent("");
                    history.push("/no-agent-found");
                  } else {
                    if (receivedSelectedAgent) {
                      let temp = json.data.agents.filter(
                        (agent) => agent._id === receivedSelectedAgent
                      );

                      if (temp.length) {
                        AgentClassService.setSelectedAgent(
                          receivedSelectedAgent
                        );
                        setSelectedAgent(receivedSelectedAgent);
                        getClasses(receivedSelectedClass, type);
                      } else {
                        receivedSelectedAgent = "";
                      }
                    }

                    if (!receivedSelectedAgent && json.data.agents.length > 1) {
                      setShowAgentSelection(true);
                    } else {
                      if (!receivedSelectedAgent) {
                        AgentClassService.setSelectedAgent(
                          json.data.agents[0]._id
                        );
                        setSelectedAgent(json.data.agents[0]._id);
                        getClasses(receivedSelectedClass);
                        if (type === "initial") {
                          getLastFeedback();
                          initialRender.current = false;
                        }
                        getSuggestedQuestions();
                        getSuggestedPrompts();
                      }

                      let temp = {};
                      json.data.agents.forEach((agent) => {
                        temp[agent._id] = agent;
                      });
                      setAgentsMap(temp);

                      receivedSelectedAgent
                        ? applyAgentSetting(
                            temp[receivedSelectedAgent].settings
                          )
                        : applyAgentSetting(json.data.agents[0].settings);
                    }
                  }
                }
              });
          }
        });
    }
  };

  const checkAgentFeatureEnabled = (
    feature,
    default_callback,
    enabled_callback = null
  ) => {    
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agent-features", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        let is_feature_enabled = false;
        if (json.status.code === 200 && json.data.features) {
          for (let i = 0; i < json.data.features.length; i++) {
            if (json.data.features[i].name === feature) {
              is_feature_enabled = true;
              break;
            }
          }
        }
        if (is_feature_enabled) {
          if (
            enabled_callback !== null &&
            typeof enabled_callback === "function"
          ) {
            enabled_callback();
          }
        } else if (typeof default_callback === "function") {
          default_callback();
        }
      });
  };

  const applyAgentSetting = (settings) => {
    // let avatarIcon = getUserAvatarIcon();
    // setUserAvatarIcon(avatarIcon);
    let webchatSettings = settings;

    if (webchatSettings.chat_company_logo) {
      setCompanyLogo(webchatSettings.chat_company_logo);
    }

    if (webchatSettings.chat_short_message) {
      setSidebarMessage(webchatSettings.chat_short_message);
    }

    if (webchatSettings.chat_bot_avatar) {
      setBotAvatar(webchatSettings.chat_bot_avatar);
      // setUserAvatarIcon(webchatSettings.chat_bot_brand_avatar);
      // } else {
      // setUserAvatarIcon(avatarIcon);
    }

    if (webchatSettings.chat_bot_name) {
      setBotName(webchatSettings.chat_bot_name);
    } else {
      setBotName("Walter");
    }

    if (webchatSettings.chat_bot_delay) {
      setBotDelay(webchatSettings.chat_bot_delay * 1000);
    } else {
      setBotDelay(2000);
    }

    const root = document.querySelector(":root");
    if (webchatSettings) {
      if (webchatSettings.chat_accent_color) {
        root.style.setProperty(
          "--primary-color",
          `#${webchatSettings.chat_accent_color}`
        );
      } else {
        root.style.setProperty("--primary-color", "#0923E6");
      }
    }

    if (webchatSettings.chat_text_color) {
      root.style.setProperty(
        "--primary-text-color",
        `#${webchatSettings.chat_text_color}`
      );
    }

    if (webchatSettings) {
      if (webchatSettings.chat_user_color) {
        root.style.setProperty(
          "--user-color",
          `#${webchatSettings.chat_user_color}`
        );
      } else {
        root.style.setProperty("--user-color", "#DCE5ED");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_user_text_color) {
        root.style.setProperty(
          "--user-text-color",
          `#${webchatSettings.chat_user_text_color}`
        );
      } else {
        root.style.setProperty("--user-text-color", "#0E0748");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_button_color) {
        root.style.setProperty(
          "--button-color",
          `#${webchatSettings.chat_button_color}`
        );
      } else {
        root.style.setProperty("--button-color", "#DCE5ED");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_button_text_color) {
        root.style.setProperty(
          "--button-text-color",
          `#${webchatSettings.chat_button_text_color}`
        );
      } else {
        root.style.setProperty("--button-text-color", "#0E0748");
      }
    }

    if (webchatSettings.customised_buttons) {
      setCustomButtonData(webchatSettings.customised_buttons);
    }
  };

  const onStartNewSession = () => {
    // setSelectedAgent("");

    // makeApiCall
    //   .request(window.CHAT_API + "/api/v1/organisation/states", {
    //     method: "PATCH",
    //     headers: authHeader(),
    //     body: JSON.stringify({
    //       states: {
    //         selected_agent: "",
    //       },
    //     }),
    //   })
    //   .then((json) => {
    //     if (json.status.code === 200) {
    setShowResumeSessionModal(false);
    getLastFeedback();
    initialRender.current = false;
    getSuggestedQuestions();
    getSuggestedPrompts();
    // if (Object.keys(agentsMap).length === 1) {
    //   onAgentChange(Object.keys(agentsMap)[0], "new");
    // } else {
    checkSelectedAgentAndClass();
    // }
    // }
    // });
  };

  const displayBotBubbles = (element,lalestChat, callBack) => {
    if (
      element.reply.object_data &&
      Object.keys(element.reply.object_data).length > 0
    ) {
      historyChatRef.current.unshift(
        <BotQuizResultBubble
          avatarIcon={botAvatar}
          submissionData={element.reply.object_data}
          submissionId={element.reply.object_id}
          // displayedQuestionsId={currentQuiz.current.questions}
          onClick={handleQuizReportAction}
          bubbleType="history"
          enableLearning={enableLearning}
        />
      );
    } else if (element.reply.bubbles) {
      element.reply.bubbles.reverse().forEach((bubble, index) => {
        if (bubble.botmode !== "buffer") {
          historyChatRef.current.unshift(
            <BotReply
              avatarIcon={botAvatar}
              bubbleIndex={index}
              key={bubble.id}
              documentId={element.document_id}
              replyId={element._id}
              replyType={bubble.template}
              data={bubble}
              replyTime={
                element.reply && element.reply.timestamp ? (
                  <Moment unix format="DD/MM/YY hh:mm A">
                    {element.reply.timestamp / 1000}
                  </Moment>
                ) : (
                  ""
                )
              }
              bookmarkAction={bookmarkAction}
              recordFeedback={recordFeedback}
              showFeedback={true}
              showReferences={element?.reply?.references?.length > 0 ? true : false}
              feedback={feedbackMap[element._id] || null}
              setDocumentPreview={setDocumentPreview}
              element={element}
              showFallback={false}
              lalestChat={lalestChat}
            />
          );
        }
      });
    } else if (element.reply.text) {
      historyChatRef.current.unshift(
        <BotChatBubble
          key={uuidv4()}
          avatarIcon={botAvatar}
          messageId={element._id}
          message={linkify(element.reply.text)}
          messageTime={
            element.reply && element.reply.timestamp ? (
              <Moment unix format="DD/MM/YY hh:mm A">
                {element.reply.timestamp / 1000}
              </Moment>
            ) : (
              ""
            )
          }
          feedback={feedbackMap[element._id] || null}
          showFeedback={true}
          recordFeedback={recordFeedback}
          bookmarkAction={bookmarkAction}
        />
      );
    }

    callBack();
  };

  const displayUserBubbles = (element) => {
    if (element.question && element.question.question) {
      historyChatRef.current.unshift(
        <UserChatBubble
          key={uuidv4()}
          avatarIcon={agentProfileImage}
          message={element.question.question}
          messageTime={
            <Moment unix format="DD/MM/YY hh:mm A">
              {element.question.timestamp / 1000}
            </Moment>
          }
          bookmarkAction={bookmarkAction}
          copyAction={copyAction}
        />
      );
    }
  };

  const setDocumentPreview = (answer, replyId) => {
    // setShowDocumentPreview(true);
    // setAnswerForDocumentPreview(answer);
    // setReplyIdForDocumentPreview(replyId);

    setShowDocumentPreview(true);
    setAnswerForDocumentPreview(answer);
    setReplyIdForDocumentPreview(replyId);
    generateContent(replyId);
  };

  useEffect(() => {
    if (showTooltip) {
      updateScrollbar()
    }
  }, [showTooltip]);

  const fetchChat = (sessionId) => {
    setChatData([]);
    chatMessagesRef.current = [];
    historyChatRef.current = [];

    let url;

    // if (lastLog._id) {
    //   url = `/api/v1/chatlog/logs?session_id=${sessionId}&last_log_id=${lastLog._id}`;
    // } else {
    url = `/api/v1/chatlog/logs?session_id=${sessionId}`;
    // }

    setSnackbar({
      isOpen: true,
      message: t("Loading conversations") + "..",
      type: "bubble",
    });

    makeApiCall
      .request(window.CHAT_API + url, {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          let chatLogs = json.data.logs;

          if (chatLogs && chatLogs.length && chatLogs[0].context) {
            if ("is_input_disabled" in chatLogs[0].context) {
              setHideChatInput(chatLogs[0].context.is_input_disabled);
            }
          }

          lastLog.current = json.data.last_log_id;
          let latestChat = chatLogs.length > 0 ? chatLogs[0] : {};
          chatLogs.forEach((element) => {
            if (element.reply) {
              displayBotBubbles(element,latestChat?._id, () => {
                displayUserBubbles(element);
              });
            }
          });

          if (chatLogs && chatLogs.length) {
            if (
              chatLogs[0].reply &&
              chatLogs[0].reply.buttons &&
              chatLogs[0].reply.buttons.length > 0
            ) {
              historyChatRef.current.push(
                <UserChatBubble
                  isButtonContainer
                  key={uuidv4()}
                  avatarIcon={AvatarIcon}
                  message={chatLogs[0].reply.buttons.map((button, index) => (
                    <SuggestionButton
                      key={index}
                      buttonLabel={button.label}
                      buttonAction={() => onCustomButtonClick(button)}
                    />
                  ))}
                  showFeedback={false}
                />
              );
            }
          }
          setChatData(historyChatRef.current);
          updateScrollbar();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });

    setChatContext("");
    // getSuggestedQuestions();
  };

  const openPastSessionPanel = (activeTab) => {
    setPastSessionActiveTab(activeTab);
    let rightPanel = document.getElementById("past-session-panel");

    if (rightPanel) {
      rightPanel.classList += " open-panel-wrap";
    }
  };

  const onPastSessionClick = (
    lastLog,
    sessionId,
    time,
    activeTab,
    resumePastSessions = false
  ) => {
    if (resumePastSessions) {
      callResumeSessionApi(sessionId);
    } else {
      setSessionTime(Math.floor(time / 60));
      openPastSessionPanel(activeTab);
      getSummary(sessionId);
      let url;

      // if (lastLog._id) {
      //   url = `/api/v1/chatlog/logs?session_id=${sessionId}&last_log_id=${lastLog._id}`;
      // } else {
      url = `/api/v1/chatlog/logs?session_id=${sessionId}`;
      // }

      makeApiCall
        .request(window.CHAT_API + url, {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          if (json.status.code === 200) {
            setPastSessionData(json.data.logs);
          } else {
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
        });

      setChatContext("");
    }
  };

  const onScroll = useMemo(() => {
    const throttled = throttle(
      (e) =>
        e.target.scrollTop > 1 && e.target.scrollTop < 5
          ? fetchChat(false)
          : null,
      300
    );
    return (e) => {
      e.persist();
      return throttled(e);
    };
  }, []);

  const getCurrentSession = (callback) => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/chatlog/sessions/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        let error = false;
        if (json && (json.status.code === 200 || json.status.code === 404)) {
          if (json.data && json.data.session) {
            callback(json.data.session);
          } else {
            callback(null);
          }
        } else {
          error = true;
        }

        if (error) {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const getOrganisationName = () => {
    // let avatarIcon = getUserAvatarIcon();
    // setUserAvatarIcon(avatarIcon);

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/organisations/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.organisation && json.data.organisation.name) {
            setTitle(`${json.data.organisation.name} - Chat`);
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const addBookmark = (text, isAgent) => {
    makeApiCall
      .request(window.CHAT_API + "/api/v1/bookmark/bookmarks", {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          text: text,
          is_agent: isAgent,
        }),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setTriggerBookmarkLoad((data) => !data);
          setSnackbar({
            isOpen: true,
            message: "Bookmark is added successfully.",
            type: "success",
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const bookmarkAction = (referrer, message) => {
    addBookmark(message, referrer === "bot");
  };

  const copyAction = (message) => {
    // put action here
    alert(message);
  };

  const getGreetings = (isClear, isNewSession = false) => {
    let body = {
      is_greeting: true,
      source: "webchat",
      starts_new_session: isNewSession
    };

    if (isClear) {
      body = {
        ...body,
        is_refresh: true,
      };
    }

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body,
    };
    setChatData([]);
    chatMessagesRef.current = [];
    startListenChat(requestOptions)
  };

  const getSuggestedQuestions = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    let requestUrl = window.CHAT_API + "/api/v1/chatbot/suggested-questions";

    if (chatContext) {
      requestUrl = requestUrl + "?topic_ids=" + chatContext;
    }

    makeApiCall.request(requestUrl, requestOptions).then((json) => {
      if (json.status.code === 200) {
        setSuggestedQuestionsData(json.data.suggested_questions);
      } else {
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
    });
  };

  const postFeedback = (feedback, key) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ type: feedback }),
    };
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatbot/replies/" + key + "/feedback",
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          // setSnackbar({
          //   isOpen: true,
          //   message: "Thank you for your feedback.",
          //   type: "success",
          // });
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const recordFeedback = (feedback, key) => {
    let feedbacks = { ...feedbackMap };
    feedbacks[key] = feedback;
    setFeedbackId(key);
    setFeedbackMap(feedbacks);
    if (feedback === 0) {
      setOpenModal(true);
    } else {
      postFeedback(feedback, key);
    }
  };

  const beforeLogout = () => {
    setOpenFeedbackModal(true);
    setLogoutAfterFeedback(true);
  };

  const logoutUser = async () => {
    await endSession("logout");
    let popupParams = "";

    if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
      let initialToken = localStorage.getItem("widget_initial_token");
      setAuthTokens(false);
      localStorage.clear();
      AgentClassService.clear();
      window.location.href = `/?t=${initialToken}`;
    } else if (window.SSO_PROVIDER === "auth0") {
      const isThorughPopup = localStorage.getItem("agentThroughAPI");
      popupParams = isThorughPopup
        ? isThorughPopup && isThorughPopup !== "null"
          ? `?login=popup&viewmode=widget&agent=${isThorughPopup}&through=admin-portal`
          : "?login=popup&viewmode=widget"
        : "";
      localStorage.clear();
      AgentClassService.clear();
      logout({
        returnTo: window.location.origin + popupParams,
      });
    } else {
      const isThorughPopup = localStorage.getItem("agentThroughAPI");
      popupParams = isThorughPopup
        ? isThorughPopup && isThorughPopup !== "null"
          ? `login=popup&viewmode=widget&agent=${isThorughPopup}&through=admin-portal`
          : "?login=popup&viewmode=widget"
        : "";
      localStorage.clear();
      AgentClassService.clear();
      setAuthTokens(false);
      // If not through external popup
      if (popupParams) {
        let newWindow = window.open(
          window.SSO_DOMAIN +
            "/logout?redirect_uri=" +
            window.location.origin +
            "&" +
            popupParams,
          "logout",
          "width=300,height=300"
        );
        setTimeout(() => {
          newWindow.close();
          window.location.href = window.location.origin + "?" + popupParams;
        }, 1000); //1 sec
      } else {
        localStorage.clear();
AgentClassService.clear();
        setAuthTokens(false);
        window.location.href =
          window.SSO_DOMAIN + "/logout?redirect_uri=" + window.location.origin;
      }
    }
  };

  const endSession = (logout = "", throughLoginPopup = false) => {
    let popupParams = "";
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
      };

      if (!AgentClassService.getSelectedAgent()) {
        return;
      }
      
      makeApiCall
        .request(
          window.CHAT_API + "/api/v1/chatlog/sessions/current",
          requestOptions
        )
        .then(async (json) => {
          if (json.status.code === 200) {
            gaEndSession();
            resolve(json);
          } else {
            if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
              let initialToken = localStorage.getItem("widget_initial_token");
              setAuthTokens(false);
              localStorage.clear();
AgentClassService.clear();
              window.location.href = `/?t=${initialToken}`;
            } else if (window.SSO_PROVIDER === "auth0") {
              if (throughLoginPopup) {
                const isThorughPopup = await localStorage.getItem(
                  "agentThroughAPI"
                );
                popupParams = isThorughPopup
                  ? isThorughPopup && isThorughPopup !== "null"
                    ? `?login=popup&viewmode=widget&agent=${isThorughPopup}&through=admin-portal`
                    : "?login=popup&viewmode=widget"
                  : "";
                localStorage.clear();
AgentClassService.clear();
                if (isThorughPopup) {
                  localStorage.setItem("agentThroughAPI", isThorughPopup);
                }
                logout({
                  returnTo: window.location.origin + popupParams,
                });
              } else {
                localStorage.clear();
AgentClassService.clear();
                logout({
                  returnTo: window.location.origin,
                });
              }
            } else {
              if (throughLoginPopup) {
                const isThorughPopup = await localStorage.getItem(
                  "agentThroughAPI"
                );
                popupParams = isThorughPopup
                  ? isThorughPopup && isThorughPopup !== "null"
                    ? `&login=popup&viewmode=widget&agent=${isThorughPopup}&through=admin-portal`
                    : "?login=popup&viewmode=widget"
                  : "";
                localStorage.clear();
AgentClassService.clear();
                if (isThorughPopup) {
                  localStorage.setItem("agentThroughAPI", isThorughPopup);
                }
                setAuthTokens(false);
                window.location.href =
                  window.SSO_DOMAIN +
                  "/logout?redirect_uri=" +
                  window.location.origin +
                  popupParams;
              } else {
                localStorage.clear();
AgentClassService.clear();
                setAuthTokens(false);
                window.location.href =
                  window.SSO_DOMAIN +
                  "/logout?redirect_uri=" +
                  window.location.origin;
              }
            }
          }
        });
    });
  };

  const sendFeedbackComment = () => {
    // postFeedback(feedbackMap[feedbackId], feedbackId);
    if (feedbackComment) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ type: 0, comment: feedbackComment }),
      };

      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/chatbot/replies/" +
            feedbackId +
            "/feedback",
          requestOptions
        )
        .then((json) => {
          if (json.status.code === 200) {
            // setSnackbar({
            //   isOpen: true,
            //   message: "Thank you for your feedback.",
            //   type: "success",
            // });
          } else {
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
        });
    }
    setFeedbackComment("");
    setOpenModal(false);
  };

  const sendSessionFeedback = () => {
    if (sessionFeedback) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          rating: sessionFeedback,
          comment: sessionFeedbackComment,
        }),
      };

      makeApiCall
        .request(window.CHAT_API + "/api/v1/chatlog/feedbacks", requestOptions)
        .then((json) => {
          if (json.status.code === 200) {
            setSnackbar({
              isOpen: true,
              message: "Thank you for your feedback.",
              type: "success",
            });
            setOpenFeedbackModal(false);
            setOpenFeedbackContainer(false);
            setSessionFeedbackComment("");
            setSessionFeedback(0);

            if (logoutAfterFeedback === true) {
              setSnackbar({
                isOpen: true,
                message:
                  "Thank you for your feedback! You will be logged out in 3 seconds.",
                type: "success",
              });

              setTimeout(function () {
                logoutUser();
              }, 3000);
            }
          } else {
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
        });
    }
    setFeedbackComment("");
    setOpenModal(false);
  };

  const onCustomButtonClick = async (button, origin) => {
    if (button && button.type && button.type === "question") {
      setMessageCopy(button.label);
      return;
    }

    let type = "",
      referenceId = "";

    if (button.reference) {
      type = button.reference.split("::")[0];
      referenceId = button.reference.split("::")[1];
    } else if (button.reference_id && button.type) {
      type = button.type;
      referenceId = button.reference_id;
    }

    if (
      button &&
      button.type &&
      (button.type === "searchoutside" ||
        button.type === "tellmemore" ||
        button.type === "expandonthat")
    ) {
      setButtonReference(button.reference);
      setMessageCopy(button.label);
      // chatMessagesRef.current.pop();
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
      setDisableCustomButton(true);

      return;
    }
    if (
      button &&
      button.type &&
      button.type !== "trigger" &&
      button.type !== "return_to_normal"
    ) {
      makeApiCall
        .request(
          window.CHAT_API +
            `/api/v1/chatbot/button-data/${type}::${referenceId}`,
          {
            method: "GET",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
            let temp = {};
            if ("form" in json.data.button_data) {
              temp = {
                form: json.data.button_data.form,
                type: "form",
              };
            } else if ("quiz" in json.data.button_data) {
              temp = {
                quiz: json.data.button_data.quiz,
                type: "quiz",
              };
            } else if (json.data.button_type === "intent") {
              temp = {
                question: json.data.button_data.question,
                reference: button.reference
                  ? button.reference
                  : button.reference_id,
                type: "intent",
              };
            } else {
              temp = {
                question: json.data.button_data.question,
                reference: button.reference
                  ? button.reference
                  : button.reference_id,
                type: "question",
              };
            }

            onChatButtonClick(temp, origin);
          }
        });
    }
  };

  // const onCustomButtonClick = (button, origin) => {
  //   setDisableCustomButton(true);
  //   let type = "",
  //     referenceId = "";

  //   if (button.reference) {
  //     type = button.reference.split("::")[0];
  //     referenceId = button.reference.split("::")[1];
  //   } else if (button.reference_id && button.type) {
  //     type = button.type;
  //     referenceId = button.reference_id;
  //   }

  //   makeApiCall
  //     .request(
  //       window.CHAT_API + `/api/v1/chatbot/button-data/${type}::${referenceId}`,
  //       {
  //         method: "GET",
  //         headers: authHeader(),
  //       },
  //       true
  //     )
  //     .then((json) => {
  //       if (json.status.code === 200) {
  //         let temp = {};
  //         if ("form" in json.data.button_data) {
  //           temp = {
  //             form: json.data.button_data.form,
  //             type: "form",
  //           };
  //         } else if ("quiz" in json.data.button_data) {
  //           temp = {
  //             quiz: json.data.button_data.quiz,
  //             type: "quiz",
  //           };
  //         } else if (json.data.button_type === "intent") {
  //           temp = {
  //             question: json.data.button_data.question,
  //             reference: button.reference
  //               ? button.reference
  //               : button.reference_id,
  //             type: "intent",
  //           };
  //         } else {
  //           temp = {
  //             question: json.data.button_data.question,
  //             reference: button.reference,
  //             type: "question",
  //           };
  //         }

  //         onChatButtonClick(temp, origin);
  //       }
  //     });
  // };

  const onChatButtonClick = (button, origin) => {
    setDisableCustomButton(false);
    switch (button.type) {
      case "form":
        if (typeof button.form.layout === "string") {
          button.form.layout = JSON.parse(button.form.layout);
        }
        openFormScreen(button.form);
        break;
      case "quiz":
        setDisableQuizButton(false);
        openQuizGeneratorScreen(button.quiz);
        break;
      case "question":
        if (button.reference) {
          setButtonReference(button.reference);
        }
        if (chatMessagesRef.current.length > 0 && origin !== "custom") {
          if (origin !== "quiz") {
            chatMessagesRef.current.pop();
          }
          setChatData(chatMessagesRef.current);
        }
        setMessageCopy(button.question);
        break;
      case "intent":
        if (button.reference) {
          let type = button.type,
            referenceId = button.reference;
          if (button.reference && button.reference.includes("::")) {
            type = button.reference.split("::")[0];
            referenceId = button.reference.split("::")[1];
          }
          setButtonReference(`${type}::${referenceId}`);
        }
        if (chatMessagesRef.current.length > 0 && origin !== "custom") {
          if (origin !== "quiz") {
            chatMessagesRef.current.pop();
          }
          setChatData(chatMessagesRef.current);
        }
        setMessageCopy(button.question);
        break;
      default:
        break;
    }
  };

  const handleQuizReportAction = (
    data,
    origin,
    displayedQuestionsId,
    buttonSubmissionData
  ) => {
    setShowQuizReport({
      open: false,
      type: "success",
      data: {},
    });
    setShowQuiz(false);
    if (origin !== "conversational") {
      // getSessions();
    }

    if (data.reference || data.reference_id) {
      if (buttonSubmissionData && displayedQuestionsId) {
        chatMessagesRef.current.push(
          <BotQuizResultBubble
            avatarIcon={botAvatar}
            submissionData={buttonSubmissionData}
            onClick={() => onCustomButtonClick(data, "quiz")}
            enableLearning={enableLearning}
            displayedQuestionsId={displayedQuestionsId}
          />
        );
        setChatData(chatMessagesRef.current);
      }
      onCustomButtonClick(data, origin);
    }

    if (origin === "backToChat") {
      chatMessagesRef.current.push(
        <BotQuizResultBubble
          avatarIcon={botAvatar}
          submissionData={data}
          onClick={handleQuizReportAction}
          enableLearning={enableLearning}
          displayedQuestionsId={displayedQuestionsId}
        />
      );
      setChatData(chatMessagesRef.current);
    }
  };

  const openFormScreen = (form) => {
    setForm(form);
    setShowForm(true);
  };

  const activateLoading = () => {
    let details = {
      message: "Hang tight! Your form is being submitted.",
      heading: "Submitting...",
      buttonLabel: "",
      type: "loading",
    };
    setShowFormSubmissionMessage(true);
    setFormSubmissionMessageDetails(details);
  };

  const handleFormSubmit = async (formId, formDetails) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    activateLoading();
    if (formDetails.formData && Object.keys(formDetails.formData).length > 0) {
      let json = await makeApiCall.request(
        window.CHAT_API + "/api/v1/form/forms/" + formId + "/submissions",
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({ form_data: formDetails.formData }),
        }
      );
      if (json.status.code === 200) {
        let details = {
          message: json.data.form_reply.text
            ? json.data.form_reply.text
            : json.data.form_reply.error
            ? json.data.form_reply.error
            : "Looks like something is not right here. Please contact your admin.",
          heading: json.data.form_reply.text
            ? ""
            : json.data.form_reply.error
            ? "Oops! Submission was unsucessful."
            : "Oops! Something went wrong.",
          buttonLabel: "GO BACK TO CHAT",
          type: json.data.form_reply.text
            ? "success"
            : json.data.form_reply.error
            ? "error"
            : "error",
        };
        setShowFormSubmissionMessage(true);
        setFormSubmissionMessageDetails(details);
      } else {
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
    }
  };

  const handleSubmissionMessageAction = () => {
    setShowFormSubmissionMessage(false);
    setShowForm(false);
    getSessions();
  };

  const onAnswerInput = (value, type, questionId) => {
    if (type === "text") {
      let tempAnswers = { ...convoQuizAnswers.current };
      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        text: value,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "radio" || type === "checkbox") {
      let tempAnswers = { ...convoQuizAnswers.current };

      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        options: value,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "matching") {
      let tempAnswers = { ...convoQuizAnswers.current };
      let temp = [];
      Object.keys(value).forEach((key) => {
        temp.push({
          term: key,
          definition: value[key] ? value[key].id : "",
        });
      });
      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        matches: temp,
      };
      convoQuizAnswers.current = tempAnswers;
    }

    let currentIdIndex = Object.keys(
      currentQuiz.current.transformedQuestions
    ).indexOf(questionId);

    if (
      conversationalQuizStrategy.current === "branching" &&
      currentQuiz.current.transformedQuestions[questionId].type === "radio"
    ) {
      currentQuiz.current.transformedQuestions[questionId].options &&
        currentQuiz.current.transformedQuestions[questionId].options.forEach(
          (option) => {
            if (option.id === convoQuizAnswers.current[questionId].options[0]) {
              if (option.branch && option.branch.action) {
                if (option.branch.action === "end-quiz") {
                  askConversationalQuizQuestion(questionId, true);
                } else if (
                  option.branch.action === "jump-to-question" &&
                  option.branch.reference_id
                ) {
                  askConversationalQuizQuestion(
                    option.branch.reference_id,
                    false
                  );
                }
              } else if (
                currentIdIndex + 1 <=
                  Object.keys(currentQuiz.current.transformedQuestions).length -
                    1 &&
                currentQuiz.current.transformedQuestions
              ) {
                askConversationalQuizQuestion(
                  Object.keys(currentQuiz.current.transformedQuestions)[
                    currentIdIndex + 1
                  ],
                  false
                );
              } else if (
                currentIdIndex + 1 ===
                Object.keys(currentQuiz.current.transformedQuestions).length
              ) {
                askConversationalQuizQuestion(questionId, true);
              }
            }
          }
        );
    } else if (
      currentIdIndex + 1 <=
        Object.keys(currentQuiz.current.transformedQuestions).length - 1 &&
      currentQuiz.current.transformedQuestions
    ) {
      askConversationalQuizQuestion(
        Object.keys(currentQuiz.current.transformedQuestions)[
          currentIdIndex + 1
        ],
        false
      );
    } else if (
      currentIdIndex + 1 ===
      Object.keys(currentQuiz.current.transformedQuestions).length
    ) {
      askConversationalQuizQuestion(questionId, true);
    }
  };

  const onConversationalQuizSubmit = async () => {
    let json = await makeApiCall.request(
      window.CHAT_API +
        "/api/v1/quiz/quizzes/" +
        currentQuiz.current._id +
        "/submissions",
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          selected_question_ids: displayedQuestionsIdConvo,
          answers: Object.values(convoQuizAnswers.current),
          action: "submit",
        }),
      }
    );

    if (json.status.code === 200) {
      setTimeout(() => {
        setChatData((data) => data.slice(0, data.length - 1));
        updateScrollbar();

        setChatData((data) =>
          data.concat(
            <BotQuizResultBubble
              showLoading
              avatarIcon={botAvatar}
              submissionData={json.data.quiz_submission}
              displayedQuestionsId={displayedQuestionsIdConvo}
              onClick={handleQuizReportAction}
              updateScrollbar={updateScrollbar}
              enableLearning={enableLearning}
            />
          )
        );
        setHideChatInput(false);
        setIsConversationalQuizActive(false);
      }, 2000);

      updateScrollbar();
    } else if (json.status.code < 500) {
      setSnackbar({
        isOpen: true,
        message: "Unable to submit quiz: " + json.status.message,
        type: "error",
      });
    } else {
      // error
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const askConversationalQuizQuestion = (currentQuestionId, isLastQuestion) => {
    // let temp =
    //   currentQuiz.current.sequence[currentSequenceIndex];
    setChatData((data) =>
      data.concat(
        <BotQuizBubble
          avatarIcon={botAvatar}
          questionData={
            currentQuiz.current.transformedQuestions[currentQuestionId]
          }
          questionId={currentQuestionId}
          isLastQuestion={isLastQuestion}
          onAnswerInput={onAnswerInput}
          onSubmit={onConversationalQuizSubmit}
          updateScrollbar={updateScrollbar}
        />
      )
    );
    updateScrollbar();
  };

  const openQuizGeneratorScreen = (selectedQuiz) => {
    if (selectedQuiz.display_mode === "conversational") {
      // chatMessagesRef.current.pop();
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);

      currentQuiz.current = selectedQuiz;

      setHideChatInput(true);
      setIsConversationalQuizActive(true);
      let temp = {};
      selectedQuiz.questions.forEach((question) => {
        if (
          question.type === "text" ||
          question.type === "bullet" ||
          question.type === "newbullet"
        ) {
          temp[question.id] = {
            text: "",
            question_id: question.id,
          };
        } else if (
          question.type === "table" ||
          question.type === "description"
        ) {
          temp[question.id] = {
            text: "[]",
            question_id: question.id,
          };
        } else if (question.type === "matching") {
          temp[question.id] = {
            matches: [],
            question_id: question.id,
          };
        } else if (question.options) {
          temp[question.id] = {
            options: [],
            question_id: question.id,
          };
        }
      });
      convoQuizAnswers.current = temp;
      transformQuizQuestions();
    } else {
      setQuiz(selectedQuiz);
      setShowQuiz(true);
    }
  };

  const transformQuizQuestions = () => {
    let temp = {};
    currentQuiz.current.questions.forEach((question) => {
      if (question.parent_id && temp[question.parent_id]) {
        temp[question.parent_id] = {
          ...temp[question.parent_id],
          subquestions:
            "subquestions" in temp[question.parent_id]
              ? temp[question.parent_id].subquestions.concat([question])
              : [].concat([question]),
        };
      } else {
        temp[question.id] = question;
      }
    });
    currentQuiz.current.transformedQuestions = temp;
    initialiseConversationalQuiz();
  };

  const initialiseConversationalQuiz = () => {
    let displayCount = currentQuiz.current.display_questions_count
      ? currentQuiz.current.display_questions_count
      : currentQuiz.current.transformedQuestions.length;

    if (
      displayCount &&
      displayCount < currentQuiz.current.transformedQuestions.length
    ) {
      conversationalQuizStrategy.current = "random";
    }

    Object.keys(currentQuiz.current.transformedQuestions).forEach(
      (questionId) => {
        if (currentQuiz.current.transformedQuestions[questionId].options) {
          currentQuiz.current.transformedQuestions[questionId].options.forEach(
            (option) => {
              if (option.branch && option.branch.action) {
                conversationalQuizStrategy.current = "branching";
              }
            }
          );
        }
      }
    );

    if (conversationalQuizStrategy.current === "random") {
      let toDisplay = getNRandomArrayIndices(
        Object.keys(currentQuiz.current.transformedQuestions),
        displayCount
      );

      let result = Object.keys(currentQuiz.current.transformedQuestions);
      let temp = toDisplay.map((item) => result[item]);
      setDisplayedQuestionsIdConvo(temp);
      currentQuiz.current.sequence = toDisplay; // to be removed
      askConversationalQuizQuestion(temp[0], false);
    } else {
      setDisplayedQuestionsIdConvo(
        Object.keys(currentQuiz.current.transformedQuestions)
      );
      //old
      let toDisplay = new Array(displayCount);
      for (let i = 0; i < displayCount; i++) {
        toDisplay[i] = i;
      }
      currentQuiz.current.sequence = toDisplay;
      askConversationalQuizQuestion(
        Object.keys(currentQuiz.current.transformedQuestions)[0],
        false
      );
    }
  };

  const handleQuizQuit = (quizId, data, displayedQuestionsId) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    setQuizDisplayedQuestions(displayedQuestionsId);
    if (data) {
      quizSubmissionPost(quizId, data, "quit", displayedQuestionsId);
    }
  };

  const handleQuizSubmit = (quizId, data, displayedQuestionsId) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    setQuizDisplayedQuestions(displayedQuestionsId);
    setDisableQuizButton(true);
    if (data) {
      quizSubmissionPost(quizId, data, "submit", displayedQuestionsId);
    }
  };

  const quizSubmissionPost = async (
    quizId,
    data,
    actionType,
    displayedQuestionsId
  ) => {
    let json = await makeApiCall.request(
      window.CHAT_API + "/api/v1/quiz/quizzes/" + quizId + "/submissions",
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          selected_question_ids: displayedQuestionsId,
          answers: data,
          action: actionType.toLowerCase(),
        }),
      }
    );

    if (json.status.code === 200) {
      // successfull
      if (actionType === "submit") {
        setShowQuizReport({
          open: true,
          type: "success",
          data: json.data.quiz_submission,
        });
        return json.status.quiz_submission;
      } else if (actionType === "quit") {
        setShowQuizReport({
          open: true,
          type: "quit",
          data: json.data.quiz_submission,
        });
        return json.status.quiz_submission;
      }
    } else if (json.status.code < 500) {
      setSnackbar({
        isOpen: true,
        message: "Unable to submit quiz: " + json.status.message,
        type: "error",
      });
    } else {
      // error
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const createBotReply = async (
    newData,
    allData,
    showFeedback,
    showFallback = false
  ) => {
    let documentPreviewSetting;
    // console.log({ newData });
    if (newData && newData.reply && newData.reply.document_id) {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      let json = await makeApiCall.request(
        window.CHAT_API +
          "/api/v1/convos/documents/" +
          newData.reply.document_id +
          "/settings",
        requestOptions
      );

      if (json.status.code === 200) {
        documentPreviewSetting = json.data.document.settings;
      }
    }

    const replyBubbles = newData.reply.bubbles;
    if (replyBubbles && replyBubbles.length > 0) {
      for (let j = 0; j < replyBubbles.length; j++) {
        await sleep(replyBubbles[j].delay || 0);
        // console.log({botMode})
        let element = newData;
        // if (!newData.context) {
        //   element.context = { botMode: botModeRef.current };
        // }
        const botReply = (
          <BotReply
            avatarIcon={botAvatar}
            bubbleIndex={j}
            key={replyBubbles[j].id}
            documentId={newData.reply.document_id}
            replyId={replyBubbles[j].reply_id}
            replyType={replyBubbles[j].template}
            data={replyBubbles[j]}
            replyTime={<Moment format="DD/MM/YY hh:mm A">{new Date()}</Moment>}
            bookmarkAction={bookmarkAction}
            recordFeedback={recordFeedback}
            showFeedback={true}
            showReferences={replyBubbles[j]?.reply?.references?.length > 0 ? true : false}
            feedback={feedbackMap[newData.reply.id] || null}
            documentPreviewSetting={documentPreviewSetting}
            setDocumentPreview={setDocumentPreview}
            element={{
              ...newData,
              context: { botmode: replyBubbles[j]?.botmode },
            }}
            showFallback={replyBubbles[j]?.botmode === "improvising"}
            lalestChat={replyBubbles[j].reply_id}
            isLoadingTyping={true}  
            />
        );
        const newChat = chatDataRef.current;

        for (let i = 0; i < newChat.length; i++) {
          let reply = newChat[i];
          if (reply?.key?.includes("typing-bubble")) {
            newChat[i] = { ...botReply };
            break;
          }
        }
        setChatData(newChat);
      }
    }

    if (
      newData.reply &&
      newData.reply.buttons &&
      newData.reply.buttons.length
    ) {
      await sleep(newData.reply.buttons[0].delay || 0);
      setChatData((data) =>
        data.concat(
          <UserChatBubble
            isButtonContainer
            key={uuidv4()}
            avatarIcon={agentProfileImage}
            isPagingBtn={newData.reply.buttons.length > 4}
            message={
              <ButtonPaging
                onCustomButtonClick={onCustomButtonClick}
                buttons={newData.reply.buttons}
                isMobile={true}
              />
            }
            showFeedback={false}
          />
        )
      );
    }
    setIsWaitRender(false);
    queueService.executeNextTask();
  };

  const onFieldChange = (value, label) => {
    if (value.length > 0) {
      setShowTooltip(false);
    }

    if (label === "feedbackComment") {
      setFeedbackComment(value);
    } else if (label === "newMessage") {
      if (suggestionsExpanded) {
        let prompts = [];
        let copiedPrompts = [...copiedSuggestedPrompts];
        if (copiedPrompts && copiedPrompts.length > 0) {
          if (value && value.trim().length > 0) {
            prompts = copiedPrompts.filter((e) => e.text.includes(value));
            setSuggestedPrompts([...prompts]);
          } else {
            setSuggestedPrompts([...copiedPrompts]);
          }
        } else {
          setSuggestedPrompts([]);
        }
      }
      setNewMessage(value);
    } else if (label === "sessionFeedbackComment") {
      setSessionFeedbackComment(value);
    }
  };

  const createReply = async (data) => {
    let conversation = [...chatData];
    await createBotReply(
      data,
      conversation,
      false,
      data && data.context && data.context.botmode === "improvising"
    );
  };

  const replaceBubble = async (b) => {
    await sleep(b.delay | 0);
    const newChats = [...chatDataRef.current];
    const index = newChats.findIndex((c) => c.props?.data?.id === b?.id);
    if (index >= 0) {
      let replaceBubble;
      // console.log(
      //   "queue update/replace bubble: ",
      //   newChats[index].props?.data?.id
      // );
      if (b.eventType === "APPEND_BUBBLE") {
        replaceBubble = { ...newChats[index] };
        replaceBubble.props.data.html = `<span>${replaceBubble.props.data.html}${b.html}</span>`;
        replaceBubble.props.data.text = replaceBubble.props.data.text + b.text;
        replaceBubble.props.data.document_preview = b.document_preview;
      } else if (b.eventType === "UPDATE_BUBBLE") {
        replaceBubble = { ...newChats[index] };
        replaceBubble.props.data.html = b.html;
        replaceBubble.props.data.text = b.text;
        replaceBubble.props.data.document_preview = b.document_preview;
      }

      if(b.eventType === "FINISH_BUBBLES" || b.eventType === "FINISH_REPLIES"){
        const newChatsUpdated = newChats.map((c, i) => {return {...c, props: { ...c.props,isLoadingTyping: false }}});
        setChatData(newChatsUpdated);
      } else{
      const newChatData = newChats.map((c, i) =>
        i === index ? replaceBubble : {...c, props: { ...c.props,lalestChat:false,isLoadingTyping: false }}
      );

      setChatData(newChatData);
      }
      queueService.executeNextTask();
      
    } else {
      console.log(
        "%c error edit bubble " + b.id + ", not found in rendered bubble",
        "background: white; color: #ad3da3"
      );
      console.log("rendered bubble: ");
      console.log(chatDataRef.current);
      queueService.executeNextTask();
    }
    // queueService.executeNextTask();
    setIsWaitRender(false);
  };

  const startListenChat = async (requestOptions) => {
    let token = null;
    addTypingBubble();
    if (
      (process.env && process.env.REACT_APP_TYPE === "login_widget") ||
      window.SSO_PROVIDER === "oneauth"
    ) {
      token = authTokens.access_token;
    } else if (window.SSO_PROVIDER === "auth0") {
      token = await getTokenSilently();
    }
    const connectId = AgentClassService.getSelectedClass();
    const agentId = AgentClassService.getSelectedAgent();
    const param = connectId ? `&connection_id=${connectId}` : "";
    const payload = { ...requestOptions.body, asynchronous_delay: false };
    await fetchEventSource(
      window.CHAT_API +
        `/api/v1/chatbot/sse/questions?agent_id=${agentId}${param}`,
      {
        method: requestOptions.method,
        openWhenHidden: true,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        async onopen(response) {
          // console.log("---OPEN CONNECTION---");
          if (!response.ok) {
            throw response;
          }
          setDisableInput(true);
        },
        async onmessage(ev) {
          setIsWaitRender(true);
          const data = JSON.parse(ev.data);
          ev.data = JSON.parse(ev.data);
          console.log("------------------------------------------");
          console.log(ev.event);
          const logdata = data.data;
          // console.log({ logdata }, logdata.text);
          const date = new Date();
          console.log(
            "%c " + date + " " + date.getMilliseconds() + "ms",
            "background: #222; color: #bada55"
          );
          const delay =
            data.data?.bubbles?.[0]?.delay ||
            data.data?.replies?.[0].bubbles?.[0]?.delay ||
            data.data?.bubble?.delay ||
            data.data?.buttons?.[0]?.delay ||
            0;
          console.log("event delay " + delay);
          switch (ev.event) {
            case "CREATE_REPLIES":
              queueService.pushTask(() =>
                createReply({
                  context: data.data.context,
                  reply: data.data.replies[0],
                })
              );
              break;

            case "CREATE_BUBBLES":
              queueService.pushTask(() => {
                if (
                  !chatDataRef.current[
                    chatDataRef.current.length - 1
                  ].key?.includes("typing-bubble")
                ) {
                  addTypingBubble();
                }
                setIsLoading(false);
                createReply({
                  context: data.data.context,
                  reply: {
                    bubbles: data.data.bubbles,
                  },
                });
              });
              break;
            case "CREATE_BUTTONS":
              queueService.pushTask(() =>
                createReply({
                  context: data.data.context,
                  reply: {
                    buttons: data.data.buttons,
                  },
                })
              );
              break;
            case "APPEND_BUBBLE":
              if (data?.data?.bubble) {
                queueService.pushTask(() =>
                  replaceBubble({
                    ...data.data.bubble,
                    eventType: "APPEND_BUBBLE",
                  })
                );
              }

              break;
            case "UPDATE_BUBBLE":
              if (data?.data?.bubble) {
                queueService.pushTask(() =>
                  replaceBubble({
                    ...data.data.bubble,
                    eventType: "UPDATE_BUBBLE",
                  })
                );
              }
              break;
            case "UPDATE_CONTEXT":
              await (data?.data.delay || 0);
              // updateContext(data.data);
              break;
            case "FINISH_BUBBLES":
              // if (data?.data?.bubble) {
                queueService.pushTask(() =>
                  replaceBubble({
                    ...data.data.bubble,
                    eventType: "FINISH_BUBBLES",
                  })
                );
              // }
            case "FINISH_REPLIES":
            // if (data?.data?.bubble) {
              queueService.pushTask(() =>
                replaceBubble({
                  ...data.data.bubble,
                  eventType: "FINISH_REPLIES",
                })
              );
            // }
            break;
            case "ERROR":
              setIsLoading(false);
              break;
            default:
              break;
          }
        },
        async onclose() {
          setDisableInput(false);
          setIsLoading(false);
          console.log("---CLOSE CONNECTION---");
          queueService.pushTask(() => {
            removeTypingBubble();
            updateScrollbar();
            queueService.executeNextTask();
          });
          setIsSseClose(true);
          if (chatInputRef.current)
            chatInputRef.current.focus();
        },
        onerror(err) {
          if (err) {
            setIsSseClose(false);
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
          setIsLoading(false);
        },
      }
    );

    // }
  };

  const removeTypingBubble = () => {
    const newChatData = chatDataRef.current.filter((c) => !c.props?.isTyping);
    setChatData(newChatData);
  };

  const scrollUpToLastMsg = (isTop = true) => {
    // setTimeout(() => {
    let messageElement = document.querySelector(
      ".parent-container.custom-scrollbar"
    );
    if (messageElement && messageElement.lastChild) {
      // messageElement.lastChild.scrollIntoView(isTop, { behavior: "smooth" });
      messageElement.scrollTo({
        top: messageElement.scrollHeight,
        behavior: "smooth",
      });
    }
    // }, 20)
  };

  const fixSpace = async () => {
    // console.log('isSseClose=' +isSseClose + ' , isWaitRender= ' + isWaitRender)
    if (isSseClose && !isWaitRender) {
      await sleep(500);
      updateScrollbar();
      await sleep(500);
      // setIsAddSpace(false);
    }
  };

  useEffect(() => {
    fixSpace();
  }, [isSseClose, isWaitRender]);

  const addTypingBubble = () => {
    const typingData = {
      html: "",
      template: "text",
    };
    const element = {
      context: "",
    };
    setChatData((data) =>
      data.concat(
        <BotReply
          data={typingData}
          key={uuidv4() + " typing-bubble"}
          avatarIcon={botAvatar}
          showFallback={true}
          replyTime={<Moment format="DD/MM/YY hh:mm A">{new Date()}</Moment>}
          isTyping={true}
          element={element}
        />
      )
    );
  };

  const sendMessage = async (newMessage) => {
    if (newMessage.trim()) {
      let conversation = [...chatData];

      setChatData((data) =>
        data.concat(
          <UserChatBubble
            key={uuidv4()}
            avatarIcon={agentProfileImage}
            message={newMessage}
            messageTime={prettifyDate(new Date())}
            bookmarkAction={bookmarkAction}
            copyAction={copyAction}
          />
        )
      );

      let payload = { question: newMessage, source: "webchat" };
      if (promptsMessage && "_id" in promptsMessage) {
        const temp = {
          ...payload,
          suggested_prompt_id: promptsMessage._id,
        };
        payload = temp;
        setPromptsMessage(null);
      }
      if (buttonReference) {
        payload.button_reference = buttonReference;
      }

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: payload,
      };
      // setChatData(conversation);

      setNewMessage("");
      setButtonReference("");
      setIsSseClose(false);
      // setIsAddSpace(true);
      await sleep(300);
      scrollUpToLastMsg();
      startListenChat(requestOptions);
    }
  };

  const handleSuggestionSelect = (key) => {
    setSelectedSuggestion(key);
    setMessageCopy(key.split("%%")[1]);
    setSuggestionsExpanded(false);
  };

  const updateScrollbar = () => {
    setTimeout(() => {
      scrollUpToLastMsg();
    }, 10);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (newMessage.length === 0) {
        event.preventDefault();
        return;
      }
      setMessageCopy(newMessage);
    }
  };

  const openNotificationPanel = () => {
    setNotificationToggle(true);
    let rightPanel = document.getElementById("notification-panel");

    if (rightPanel) {
      rightPanel.classList += " open-panel-wrap";
    }
  };

  const openFeedbackModalFunction = () => {
    setOpenFeedbackModal(true);
  };

  const onConversationClear = () => {
    setOpenSessionEndModal(false);
    // setOpenFeedbackContainer(true);
    setShowHomePanel(false);
    setChatData([]);
    getGreetings(true);
    // getSummary();
  };
  const addBotAvatar = (chatComponent, avatar) => {
    if (chatComponent?.props?.replyId && avatar) {
      return {
        ...chatComponent,
        props: {
          ...chatComponent.props,
          avatarIcon: avatar,
        },
      };
    }

    return chatComponent;
  };

  const getSuggestedPrompts = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    let requestUrl = window.CHAT_API + "/api/v1/chatbot/suggested-prompts";

    if (chatContext) {
      requestUrl = requestUrl + "?topic_ids=" + chatContext;
    }

    setTimeout(() => {
      makeApiCall.request(requestUrl, requestOptions).then((json) => {
        if (json.status.code === 200) {
          setSuggestedPrompts(json.data.suggested_prompts);
          setCopiedSuggestedPrompts(json.data.suggested_prompts);
          // let isCookieSet = getCookie("view-suggestions");
          // setShowTooltip(
          //   isCookieSet === null ? true : isCookieSet === "shown" ? false : true
          // );
          setShowTooltip(true);
        } else {
          // setSnackbar({
          //   isOpen: true,
          //   message: "Oops! Something went wrong.",
          //   type: "error",
          // });
          setSuggestedPrompts([]);
        }
      });
    }, 400);
  };

  useEffect(() => {
    if (document.getElementById("parent-container-scroll-view")) {
      document
        .getElementById("parent-container-scroll-view")
        .addEventListener("scroll", onScrollChatContainer);
    }
    return () => {
      if (document.getElementById("parent-container-scroll-view")) {
        document
          .getElementById("parent-container-scroll-view")
          .removeEventListener("scroll", onScrollChatContainer);
      }
    };
  }, []);
  
  const onScrollChatContainer = (event) => {
    const { scrollHeight, scrollTop, clientHeight, offsetHeight } = event.target;
    let lastScrollTop = 0;
    
    if (scrollTop < lastScrollTop){
      return;
    } 
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    if (scrollTop + offsetHeight>= scrollHeight ){
      setReachedEnd(true);
    } else {
      setReachedEnd(false);
    }
  };

  const handleSuggestedPromptSelect = (messageObj) => {
    setPromptsMessage(messageObj);
    // setNewMessage(message);
    // setShowPrompts(false);
    setSuggestionsExpanded(false);
  };

  const getChatPanel = (type) => {
    return (
      <>
        {selectedSidebarOption === "chat" && (
          <>
            {/* <div className="chat-messages-outer-container"> */}
            <div
              className={`parent-container custom-scrollbar`}
              id="parent-container-scroll-view"
            >
              {chatDataRef.current.map((component) =>
                addBotAvatar(component, botAvatar)
              )}
              {showTooltip && <div style={{ height: 70 }}>&nbsp;</div>}
            </div>
            <div className="chat-footer-container">
              <div className="chat-footer m-0">
                {suggestedPrompts && suggestedPrompts.length > 0 && (
                  <>
                    {!suggestionsExpanded && !showResumeSessionModal && (
                      <OverlayTrigger
                        placement="top"
                        show={showTooltip}
                        overlay={
                          <Tooltip className="suggested-prompts-tooltip">
                            <div>
                              <span>
                                {t("Not sure where to start?")}
                                <br />
                                {t("Click here to view suggestions")}
                              </span>
                              <button
                                className="btn btn-primary right-align"
                                onClick={() => {
                                  // setCookie("view-suggestions", "shown", 7);
                                  setShowTooltip(!showTooltip);
                                }}
                              >
                                {t("Got it")}
                              </button>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div
                          className="suggested-arrow-container"
                          onClick={() => {
                            setSuggestionsExpanded(true);
                            setShowTooltip(false);
                          }}
                        >
                          <i className="icon-up-chevron" />
                        </div>
                      </OverlayTrigger>
                    )}
                    {suggestionsExpanded && (
                      <div
                        className="suggested-arrow-container expanded"
                        onClick={() => {
                          setSuggestionsExpanded(false);
                        }}
                      >
                        <i className="icon-down-chevron" />
                      </div>
                    )}
                    {suggestionsExpanded && (
                      <div className="suggested-questions-parent-container">
                        <div
                          className={
                            suggestionsExpanded
                              ? "suggested-questions-container"
                              : "suggested-questions-container suggested-questions-collapsed"
                          }
                        >
                          <div className="suggested-questions-header">
                            {suggestionsExpanded ? (
                              <>
                                <div className="prompts-icon">
                                  <img src={PromptsIdeaIcon} alt="prompts" />
                                </div>
                                <div className="heading">
                                  {suggestionsExpanded &&
                                  newMessage &&
                                  newMessage.trim().length > 0
                                    ? "Search Suggestions"
                                    : "Suggestions"}
                                </div>
                              </>
                            ) : (
                              <div className="heading"></div>
                            )}
                          </div>
                          <div className="suggested-questions-body custom-scrollbar">
                            {suggestedPrompts.map((item) => (
                              <div
                                className="prompts"
                                key={`${item._id}`}
                                onClick={() => {
                                  handleSuggestedPromptSelect(item);
                                }}
                              >
                                {item.text}
                              </div>
                            ))}
                            {!suggestedPrompts &&
                              Array.apply(null, Array(5)).map((item) => (
                                <div className="prompts">
                                  <div className="empty-prompt"></div>
                                </div>
                              ))}
                            {suggestedPrompts &&
                              suggestedPrompts.length === 0 && (
                                <div>Could not find suggestions.</div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {/* {suggestedQuestionsData.length > 0 && (
                  <>
                    {!suggestionsExpanded && (
                      <div
                        className="suggested-arrow-container"
                        onClick={() => {
                          setSuggestionsExpanded(true);
                        }}
                      >
                        <i className="icon-up-chevron" />
                      </div>
                    )}
                    <div
                      className={
                        suggestionsExpanded
                          ? "suggested-questions-container"
                          : "suggested-questions-container suggested-questions-collapsed"
                      }
                    >
                      <div className="suggested-questions-header">
                        {suggestionsExpanded ? (
                          <div className="heading">Suggestions</div>
                        ) : (
                          <div className="heading"></div>
                        )}
                        <div
                          className="view-control-btn"
                          onClick={() => {
                            setSuggestionsExpanded(!suggestionsExpanded);
                          }}
                        >
                          {suggestionsExpanded ? (
                            <i className="icon-close" />
                          ) : null}
                        </div>
                      </div>
                      <div className="suggested-questions-body">
                        {suggestedQuestionsData.map((item) => (
                          <button
                            key={`${item._id}%%${item.name}`}
                            disabled={disableInput}
                            className={
                              disableInput
                                ? "topic-button-disabled"
                                : "topic-button"
                            }
                            onClick={() => {
                              handleSuggestionSelect(
                                `${item._id}%%${item.name}`
                              );
                            }}
                          >
                            {item.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </>
                )} */}
                <div
                  className={`chat-input-wrapper ${
                    suggestionsExpanded ? "suggestions-expanded" : ""
                  }`}
                >
                  <ChatInput
                    value={newMessage}
                    avatarIcon={agentProfileImage}
                    onKeyPress={handleKeyPress}
                    onSend={() => {
                      setMessageCopy(newMessage);
                    }}
                    placeholder={t("Type here to ask something") + "..."}
                    onChange={(value) => onFieldChange(value, "newMessage")}
                    onConversationClear={onConversationClear}
                    onChatInputFocus={onChatInputFocus}
                    onChatInputBlur={onChatInputBlur}
                    isDisabled={hideChatInput || disableInput}
                    disableCustomButton={disableCustomButton || disableInput}
                    isConversationalQuizActive={isConversationalQuizActive}
                    usetextArea
                    max={8}
                    inputType="text"
                    ref={chatInputRef}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {showForm && (
          <div className="chat-overlay-container">
            {showFormSubmissionMessage && (
              <FormSubmissionMessage
                message={formSubmissionMessageDetails.message}
                heading={formSubmissionMessageDetails.heading}
                buttonLabel={formSubmissionMessageDetails.buttonLabel}
                buttonAction={handleSubmissionMessageAction}
                type={formSubmissionMessageDetails.type}
                mode={formSubmissionMessageDetails.displayMode}
              />
            )}
            {!showFormSubmissionMessage && (
              <Form
                onBack={() => {
                  setShowForm(false);
                  getSessions();
                }}
                onSubmit={handleFormSubmit}
                formSchema={form.layout.formSchema}
                uiSchema={form.layout.uiSchema}
                submitButtonLabel={
                  form.layout.submitLabelText
                    ? form.layout.submitLabelText
                    : "SUBMIT"
                }
                primaryHeaderText={form.layout.headerText}
                formId={form._id}
              />
            )}
          </div>
        )}
        {showQuiz && (
          <div className="chat-overlay-container">
            {!showQuizReport.open && (
              <QuizGenerator
                quizData={quiz}
                onBack={() => {
                  setShowQuiz(false);
                  getSessions();
                }}
                onQuit={handleQuizQuit}
                onSubmit={handleQuizSubmit}
                disableSubmit={disableQuizButton}
                enableLearning={enableLearning}
              />
            )}
            {showQuizReport.open && (
              <QuizReport
                // hideScores={hideScores}
                submissionData={showQuizReport.data}
                type={showQuizReport.type}
                onClick={handleQuizReportAction}
                displayedQuestionsId={quizDisplayedQuestions}
                enableLearning={enableLearning}
              />
            )}
          </div>
        )}
        {selectedSidebarOption === "sessions" && (
          <AllSessions
            onPastSessionClick={(
              sessionId,
              lastLog,
              time,
              activeTab,
              resumePastSessions
            ) => {
              onPastSessionClick(
                lastLog,
                sessionId,
                time,
                activeTab,
                resumePastSessions
              );
            }}
            enableLearning={enableLearning}
            showChatPanel={() => {
              setSelectedSidebarOption("chat");
            }}
          />
        )}
        {selectedSidebarOption === "reports" && <UserReport />}
      </>
    );
  };

  const generateContent = (replyId) => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/convos/replies/" + replyId + "/excerpts",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setDocPreviews(json.data.excerpts);
          setEnableSearch(false);
        } else {
          if (json.status && json.status.message) {
            setSnackbar({
              isOpen: true,
              message: "Oops! " + json.status.message,
              type: "error",
            });
          }
        }
      });
  };

  const onAgentChange = async (agentId, type, forceLoginPopup = false) => {
    setChatData([]);
    setSuggestedQuestionsData([]);
    if (type === "switch") {
      await endSession("", forceLoginPopup);
    }
    AgentClassService.setSelectedAgent(agentId);
    setSelectedClass("");
    AgentClassService.setSelectedClass("");
    setSelectedAgent(agentId);
    await makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/states", {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify({
          states: {
            selected_agent: agentId.toString(),
          },
        }),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (type === "switch") {
            setShowAgentSelection(false);
            getDefaultAgent();
          } else {
            callStartSessionApi();
            setShowNewSessionAgentSelection(false);
            getSuggestedQuestions();
            getSuggestedPrompts();
            selectDefaultClass();
          }
        }
      });

    checkAgentFeatureEnabled(
      "learning",
      () => {
        setEnableLearning(false);
      },
      () => {
        setEnableLearning(true);
      }
    );
  };

  const getPopups = () => {
    return (
      <React.Fragment>
        <Snackbar
          isOpen={snackbar.isOpen}
          primaryMessage={snackbar.message}
          type={snackbar.type}
          onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        />
        <Modal
          openModal={openModal}
          onRequestClose={() => {
            postFeedback(feedbackMap[feedbackId], feedbackId);
            setFeedbackComment("");
            setFeedbackId("");
            setOpenModal(false);
          }}
          buttonLabel="Send comments"
          cancelButtonLabel="No, that's all"
          buttonAction={sendFeedbackComment}
        >
          <div className="modal-heading">
            {t(
              "I am open to feedback on how to answer better. If you think that the response to your question can be better, please tell me."
            )}
          </div>
          <TextInput
            inputType="text"
            name="feedbackComment"
            placeholder={t("Type your comments here.")}
            value={feedbackComment}
            inputChange={(event, name) =>
              onFieldChange(event.target.value, name)
            }
          />
        </Modal>
        <Modal
          openModal={openFeedbackModal}
          onRequestClose={logoutUser}
          buttonLabel="SEND FEEDBACK"
          cancelButtonLabel="LOGOUT"
          buttonAction={sendSessionFeedback}
        >
          <div className="modal-heading">{t("How was your session?")}</div>
          <RatingInput
            options={{
              enumOptions: [
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
              ],
            }}
            onChange={setSessionFeedback}
          ></RatingInput>
          {sessionFeedback !== 0 && (
            <TextInput
              inputType="text"
              name="sessionFeedbackComment"
              placeholder={
                sessionFeedback > 3
                  ? t("Tell us how your experience was.")
                  : t("Tell us what we could improve.")
              }
              value={sessionFeedbackComment}
              inputChange={(event, name) =>
                onFieldChange(event.target.value, name)
              }
            />
          )}
        </Modal>
        <NotificationPanel
          isWidget={true}
          notificationCount={notificationCount}
          onClick={onClickNotification}
          onDelete={onDeleteNotification}
          onCheckboxChange={onCheckboxChangeNotification}
          allNotifications={allNotifications}
          notificationToggle={notificationToggle}
          setNotificationToggle={setNotificationToggle}
          markAllRead={markAllRead}
          clearAll={clearAllNotification}
          onCustomButtonClick={onCustomButtonClick}
        />

        <PastSessionPanel
          pastSessionActiveTab={pastSessionActiveTab}
          sessionData={pastSessionData}
          botAvatar={botAvatar}
          userAvatarIcon={agentProfileImage}
          summaryData={summaryData}
          sessionTime={sessionTime}
          enableLearning={enableLearning}
        />
        <ResumeSessionModal
          openModal={showResumeSessionModal}
          onStartNewSession={onStartNewSession}
          resumeSession={callResumeSessionApi}
        />
        <PopupNotification
          openNotification={openPopupNotification}
          onRequestClose={() => setOpenPopupNotification(false)}
          notificationData={popupNotificationData}
          onCustomButtonClick={onCustomButtonClick}
        />
      </React.Fragment>
    );
  };

  if (showNewSessionAgentSelection) {
    return (
      <AgentSelection
        onAgentChange={(agentId) => onAgentChange(agentId, "new")}
        hideAgentSelection={() => {
          setShowNewSessionAgentSelection(false);
          updateScrollbar();
        }}
      />
    );
  }

  if (showAgentSelection) {
    return (
      <AgentSelection
        selectedAgent={selectedAgent}
        onAgentChange={(agentId) => onAgentChange(agentId, "switch")}
        hideAgentSelection={() => {
          setShowAgentSelection(false);
          updateScrollbar();
        }}
      />
    );
  }

  
  if (showOrgSelection) {
    return (
      <OrganisationSwitcher />
    )
  }

  const generateSearchContent = (keyword, preceed, succeed) => {
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/convos/replies/" +
          replyIdForDocumentPreview +
          "/content",
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            keyword: keyword,
            preceed: parseInt(preceed),
            succeed: parseInt(succeed),
          }),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setDocumentPreviewContent(json.data.content);
          setEnableSearch(true);
        } else {
          if (json.status && json.status.message) {
            setSnackbar({
              isOpen: true,
              message: "Oops! " + json.status.message,
              type: "error",
            });
          }
        }
      });
  };

  const updateTextBlock = async (documentId, textBlockId) => {
    const json = await makeApiCall.request(
      window.CHAT_API +
        "/api/v1/convos/documents/" +
        documentId +
        "/text-blocks/" +
        textBlockId,
      {
        method: "GET",
        headers: authHeader(),
      },
      true
    );
    if (json.status.code === 200) {
      setEnableSearch(false);
      return json.data;
    } else {
      if (json.status && json.status.message) {
        setSnackbar({
          isOpen: true,
          message: "Oops! " + json.status.message,
          type: "error",
        });
      }
    }
  };

  const getTextBlock = async (replyId, excerpt_id) => {
    const params = excerpt_id ? `?excerpt_id=${excerpt_id}` : "";
    const json = await makeApiCall.request(
      window.CHAT_API +
        "/api/v1/convos/replies/" +
        replyId +
        "/text-block" +
        params,
      {
        method: "GET",
        headers: authHeader(),
      },
      true
    );
    if (json.status.code === 200) {
      return json.data;
    } else {
      if (json.status && json.status.message) {
        setSnackbar({
          isOpen: true,
          message: "Oops! " + json.status.message,
          type: "error",
        });
      }
    }
  };

  // function setCookie(name, value, days) {
  //   let expires = "";
  //   if (days) {
  //     let date = new Date();
  //     date.setTime(
  //       date.getTime() +
  //         // days * 24 * 60 * 60 * 1000
  //         10 * 1000
  //     );
  //     expires = "; expires=" + date.toUTCString();
  //   }
  //   document.cookie = `${name}=${value || ""}${expires}; SameSite=None; Secure; path=/`;
  // }

  // function getCookie(name) {
  //   let nameEQ = name + "=";
  //   let ca = document.cookie.split(";");
  //   for (let i = 0; i < ca.length; i++) {
  //     let c = ca[i];
  //     while (c.charAt(0) == " ") c = c.substring(1, c.length);
  //     if (c.indexOf(nameEQ) == 0) {
  //       return c.substring(nameEQ.length, c.length);
  //     }
  //   }
  //   return null;
  // }

  return (
    <div className="widget-chat-page-container auth-sso-widget">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {widgetSettings.showPeepingImg &&
        widgetSettings.peepingImgPosition === "top" && (
          <div className="top-peeping-img-face">
            <img
              src={
                widgetSettings.peepingImgCustom
                  ? widgetSettings.peepingImgCustom
                  : DefaultPeepingFace
              }
              className="top-peeping-img"
            />
          </div>
        )}
      {widgetSettings.showPeepingImg &&
        widgetSettings.showPeepingImgHands &&
        widgetSettings.peepingImgPosition === "top" && (
          // widgetSettings.peepingImgCustom === "" && (
          <div className="peeping-img-hands">
            <img src={WalterLeftHand} className="peeping-img-left-hand" />
            <img src={WalterRightHand} className="peeping-img-right-hand" />
          </div>
        )}
      {widgetSettings.showPeepingImg &&
        widgetSettings.peepingImgPosition === "side" && (
          <div
            className={
              widgetSettings.widgetPosition === "right"
                ? "left-side-peeping-img-face"
                : "right-side-peeping-img-face"
            }
          >
            <img
              src={
                widgetSettings.peepingImgCustom
                  ? widgetSettings.peepingImgCustom
                  : DefaultPeepingFace
              }
              className="side-peeping-img"
            />
          </div>
        )}
      {widgetSettings.showPeepingImg &&
        widgetSettings.showPeepingImgHands &&
        widgetSettings.peepingImgPosition === "side" && (
          // widgetSettings.peepingImgCustom === "" && (
          <div
            className={
              widgetSettings.widgetPosition === "right"
                ? "left-side-peeping-img-hand-wrapper"
                : "right-side-peeping-img-hand-wrapper"
            }
          >
            <img
              src={WalterSideHand}
              className={
                widgetSettings.widgetPosition === "right"
                  ? "left-side-peeping-img-hand"
                  : "right-side-peeping-img-hand"
              }
            />
          </div>
        )}
      <div
        className={
          widgetSettings.widgetPosition === "right"
            ? "chat-right-position-shape"
            : "chat-left-position-shape"
        }
        style={{
          marginTop:
            widgetSettings.showPeepingImg &&
            widgetSettings.peepingImgPosition === "top"
              ? "55px"
              : "12px",
          marginLeft:
            widgetSettings.showPeepingImg &&
            widgetSettings.peepingImgPosition === "side" &&
            widgetSettings.widgetPosition === "right"
              ? "63px"
              : "12px",
          marginRight:
            widgetSettings.showPeepingImg &&
            widgetSettings.peepingImgPosition === "side" &&
            widgetSettings.widgetPosition === "left"
              ? "63px"
              : "12px",
          height:
            widgetSettings.showPeepingImg &&
            widgetSettings.peepingImgPosition === "top"
              ? "calc(100% - 70px)"
              : "calc(100% - 50px)",
          width:
            widgetSettings.showPeepingImg &&
            widgetSettings.peepingImgPosition === "side"
              ? "calc(100% - 75px)"
              : "calc(100% - 24px)",
        }}
      >
        {!showHomePanel && (
          <button className="widget-close-btn">
            <i className="icon-close" />
          </button>
        )}
        {showHomePanel && (
          <div className={"widget-chat-sidebar"}>
            <div className="">
              <IntroPanel
                isWidget={true}
                logoImg={companyLogo}
                message={sidebarMessage}
              />
            </div>
            <ChatFeaturePanel
              hideChatbotWidget={props.hideChatbotWidget || false}
              isWidget={true}
              enableLearning={enableLearning}
              onPastSessionClick={(sessionId, lastLog, time, activeTab) => {
                onPastSessionClick(lastLog, sessionId, time, activeTab);
              }}
              triggerBookmarkLoad={triggerBookmarkLoad}
              getSessions={getSessions}
              endSession={endSession}
              setNoClassHistoryPanel={(status) =>
                setToggleNoClassHistory(status)
              }
              showChatPanel={() => setShowHomePanel(false)}
              setSelectedSidebarOption={(selector) => {
                setSelectedSidebarOption(selector);
                setShowHomePanel(false);
              }}
              selectedSidebarOption={selectedSidebarOption}
            />
          </div>
        )}

        {!showHomePanel && (
          <div
            className={
              selectedSidebarOption === "chat"
                ? "chat-body-wrapper chat-body-wrapper-with-chat"
                : "chat-body-wrapper"
            }
            style={{
              borderRadius:
                widgetSettings.widgetPosition === "right"
                  ? "25px 25px 16px 25px"
                  : "25px 25px 25px 16px",
            }}
          >
            <ChatHeader
              isWidget={true}
              hideChatbotWidget={props.hideChatbotWidget || false}
              avatar={botAvatar}
              botName={botName}
              openFeedbackModal={openFeedbackModal}
              openNotificationPanel={openNotificationPanel}
              beforeLogout={beforeLogout}
              onBack={() => setShowHomePanel(true)}
              showSwitchAgent={Object.keys(agentsMap).length > 1}
              customButtonData={customButtonData}
              onCustomButtonClick={onCustomButtonClick}
              disableCustomButton={disableCustomButton || disableInput}
              isConversationalQuizActive={isConversationalQuizActive}
              enableLearning={enableLearning}
              showUnread={notificationCount > 0}
              showProfileEdit={true}
              selectedSidebarOption={selectedSidebarOption}
              onStartNewSession={onStartNewSession}
            />
            {selectedSidebarOption === "chat" && getChatPanel("widget")}
            {selectedSidebarOption === "bookmarks" && (
              <Allbookmarks triggerBookmarkLoad={triggerBookmarkLoad} />
            )}
            {selectedSidebarOption === "sessions" && (
              <AllSessions
                onPastSessionClick={(
                  sessionId,
                  lastLog,
                  time,
                  activeTab,
                  resumePastSessions
                ) => {
                  onPastSessionClick(
                    lastLog,
                    sessionId,
                    time,
                    activeTab,
                    resumePastSessions
                  );
                }}
                enableLearning={enableLearning}
                showChatPanel={() => {
                  setSelectedSidebarOption("chat");
                }}
              />
            )}
            {selectedSidebarOption === "reports" && <UserReport />}
            {getPopups()}
          </div>
        )}
        {/* {showHomePanel && (
          <div
            className="sidebar-overlay"
            onClick={() => {
              setShowHomePanel(false);
            }}
          ></div>
        )} */}
        {showDocumentPreview && (
          <div className="widget">
            <div className="document-preview-wrapper">
              <MultiDocumentPreview
                onClickBackButton={() => setShowDocumentPreview(false)}
                answer={answerForDocumentPreview}
                keyword={answerForDocumentPreview}
                onSubmit={generateSearchContent}
                content={documentPreviewContent}
                setKeyword={setAnswerForDocumentPreview}
                enableSearch={enableSearch}
                textBlockIds={textBlockIds}
                documentId={documentIdForDocumentPreview}
                updateTextBlock={updateTextBlock}
                docPreviews={docPreviews || []}
                getTextBlock={getTextBlock}
                replyId={replyIdForDocumentPreview}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginWidget;
