/* IE polyfills */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import AppWithPopup from "./AppWithPopup";
import history from "./history";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import AgentClassService from "./helpers/AgentClassService";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://725842ca352a4840a862b614049da6b0@o495331.ingest.sentry.io/6194731",
//   integrations: [new BrowserTracing()],
//   environment: window.ENVIRONMENT, // development / staging / production
//   tracesSampleRate: 0.0,
// });

// A function that routes the user to the right place
// after login

serviceWorker.unregister();

const urlParams = new URLSearchParams(window.location.search);
const login = urlParams.get("login");
const agent = urlParams.get("agent");
const through = urlParams.get("through");
const agentClass = urlParams.get("class");
AgentClassService.loadFromLocalStorage(agent, agentClass);
const chatSelectedAgent = AgentClassService.getSelectedAgent();
window.addEventListener("beforeunload", function (event) {
  AgentClassService.saveToLocalStorage();
  // if(window.opener?.document.keepAlive){
  //   localStorage.clear()
  //   AgentClassService.clear();
  // }
});

ReactDOM.render(
  <App
    login={login}
    loginWithPopup={login === "popup" && !chatSelectedAgent}
    agent={agent}
    through={through}
    agentClass={agentClass}
  />,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
