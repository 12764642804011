import React, { useEffect, useRef, useState } from "react";
import "./style.css";

import { DocumentIcon, IntentIcon } from "./../Icons";

import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";

const MultiDocumentPreview = (props) => {
  const { t } = useTranslation("translation");
  const [selectedDoc, setSelectedDoc] = useState(props.defaultReference);
  const [docPreviews, setDocPreviews] = useState([]);
  const makeApiCall = useFetch();
  const previewContainer = useRef()
  const [showAddRefModal, setShowAddRefModal] = useState(false);
  const [previewHTML, setPreviewHTML] = useState("");
  const [isLoading, setLoading] = useState(false);
  
  useEffect(() => {
    setSelectedDoc(props.defaultReference);
  }, [props.defaultReference]);

  const selectDoc = async (doc) => {
    setSelectedDoc(doc);
  };

  useEffect(() => {
    if (!props.replyId) return;
    setLoading(true);

    makeApiCall
    .request(
      `${window.CHAT_API}/api/v1/chatbot/replies/${props.replyId}/references`,
      {
        method: "GET",
        headers: authHeader(),
      },
      true
    )
    .then((json) => {
      if (json.status.code === 200)
        setDocPreviews(json?.data?.references);
        setLoading(false);
    });
  }, [props.replyId]);

  useEffect(() => {
    if (!selectedDoc) return;
    if (selectedDoc.document_preview === "intent") return;
    setLoading(true);

    makeApiCall
    .request(
      `${window.CHAT_API}/api/v1/chatbot/replies/${props.replyId}/references/${selectedDoc.id}/preview`,
      {
        method: "GET",
        headers: authHeader(),
      },
      true
    )
    .then((json) => {
      if (json.status.code === 200)
        setPreviewHTML(json.data.text_block.html);
      setLoading(false);
    });
  }, [selectedDoc]);

  useEffect(() => {
    const hightLightedNode = previewContainer.current.querySelector(".text-highlight")
    if (hightLightedNode)
      hightLightedNode.scrollIntoView()
  }, [previewHTML]);

  return (
    <>
      {props?.docPreviews.length > 0 && showAddRefModal && props.isMobile && (
        <div className="chat-modal additional-ref-modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title mb-0">
                {t("Additional References")}
              </div>
              <button
                className="close mr-0 position-static px-0"
                onClick={() => {
                  setShowAddRefModal(false);
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body p-3">
              {t(
                "The answer may be supported by multiple sources. Click the 'View' icon to explore additional references used in the answer."
              )}
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-outline-primary m-0 py-1"
                onClick={() => {
                  setShowAddRefModal(false);
                }}
              >
                {t("Close")}
              </button>
            </div>
          </div>
        </div>
      )}
      <>
        <div className="option-question-container">
          {props.onClickBackButton && (
            <div
              className="back-button-wrapper"
              onClick={props.onClickBackButton}
            >
              <button className="header-back-btn">
                <i className="icon-left-chevron" />
              </button>
              <div className="doc-header-heading">{t("Go back to chat")}</div>
            </div>
          )}
          <div className="half-clearfix" />
          <div className="custom-scrollbar sidebar-scroll-content">
            <div className="page-subheading">{t("View Document")}</div>
            <div className="reference-preview-container">
              <div className="reference-preview" dangerouslySetInnerHTML={{ __html: previewHTML }} ref={previewContainer} />
              {isLoading && <div className="preview-loader">Loading...</div>}
              {selectedDoc.document_preview === "intent" && <div className="preview-loader">Document Preview unavailable</div>}
            </div>

            <div className="half-clearfix" />
            {docPreviews.length > 0 && (
              <>
                <div className="half-clearfix" />
                <div className="page-subheading additional-reference">
                  <div className="">
                    {t("References")}
                  </div>
                </div>

                <div>
                  {docPreviews?.map((doc) => (
                    <div
                      key={doc.id}
                      className="content-preview-wrapper"
                    >
                      <div
                        className={
                          classNames(
                            "doc-title",
                            {
                              "active": selectedDoc.id === doc.id,
                            }
                          )
                        }
                        onClick={() => selectDoc(doc)}
                      >
                        <span className="doc-icon">
                          {doc?.icon === "document" ? <DocumentIcon /> : <IntentIcon />}
                        </span>
                        <span className="doc-name">{`${doc?.name}`}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default MultiDocumentPreview;
