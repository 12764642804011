import React from "react";

import Card from "../Card";
import Button from "../Button";
import "./style.css";
import logoImgNew from '../../assets/images/walter-head-newlogo-circle.png';

import { useTranslation } from "react-i18next";

const IntroPanel = ({
  logoImg,
  message,
  startNewConversation,
  handleHideSidebar,
  isWidget,
}) => {
  const { t } = useTranslation('translation');
  return (
    <div className="intro-panel-container">
      <div className="intro-panel-card">
        {!isWidget && (
          <button
            className="hide-sidebar-btn is-hidden-mobile"
            title={t("Hide sidebar")}
            onClick={handleHideSidebar}
            aria-label={t("Toogle Sidebar")}
            aria-expanded={true}
          >
            <i className="icon-eye-off" />
          </button>
        )}
        <div className="logo-container">
          {logoImg && <img className="logo-img" src={logoImg} alt={t("Company")} />}
        </div>
        <h1
          className="message-container"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>
      {/* <Button
        buttonClass="primary-button custom-button"
        onClick={startNewConversation}
      >
        Start new chat session
      </Button> */}
    </div>
  );
};

export default IntroPanel;
